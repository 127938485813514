import React, { useContext, useState } from 'react'

import { API } from 'API'
import { CasesPageContext } from 'containers/CasesPage/CasesPageContext'
import { RequestToasterContext } from 'containers/Providers'
import { differenceInSeconds } from 'date-fns'
import { Case } from 'domains/case'
import { UserGender } from 'domains/user'
import { StandardErrorResponse } from 'types/APIResponses'

export const CasePatient = () => {
  const { casesPageState, getCase } = useContext(CasesPageContext)
  const { id, patient } = casesPageState.userCase as Case
  const { requestStatusRef } = useContext(RequestToasterContext)

  const [patientFirstName, setPatientFirstName] = useState(patient.personFirstName)
  const handleChangePatientFistName = (event: React.ChangeEvent<HTMLInputElement>) =>
    setPatientFirstName(event.target.value)

  const [patientLastName, setPatientLastName] = useState(patient.personLastName)
  const handleChangePatientLastName = (event: React.ChangeEvent<HTMLInputElement>) =>
    setPatientLastName(event.target.value)

  const [patientGender, setPatientGender] = useState<UserGender>(patient.personGender)
  const handleChangePatientGender = (event: React.ChangeEvent<HTMLSelectElement>) =>
    setPatientGender(event.target.value as UserGender)

  const [patientBirthDate, setPatientBirthDate] = useState(patient.personBirthDate)
  const handleChangePatientBirthDate = (event: React.ChangeEvent<HTMLInputElement>) =>
    differenceInSeconds(new Date(), new Date(event.target.value)) < 0
      ? requestStatusRef.current?.showAlert('Enter valid date', 'danger')
      : setPatientBirthDate(event.target.value)

  const handleUpdatePatientName = () => {
    requestStatusRef.current?.startProgress('Renaming patient...', 'secondary')
    API.updateCasePatientById({
      id,
      firstName: patientFirstName,
      lastName: patientLastName,
    })
      .then(() => {
        getCase()
        requestStatusRef.current?.showAlert('Updated', 'success')
      })
      .catch((error: StandardErrorResponse) => {
        requestStatusRef.current?.showAlert(error.response.data.error, 'danger')
      })
  }

  const handleUpdatePatientGender = () => {
    requestStatusRef.current?.startProgress('Updating...', 'secondary')
    API.updateCasePatientById({
      id: id,
      gender: encodeURIComponent(patientGender),
    })
      .then(() => {
        getCase()
        requestStatusRef.current?.showAlert('Updated', 'success')
      })
      .catch((error: StandardErrorResponse) => {
        requestStatusRef.current?.showAlert(error.response.data.error, 'danger')
      })
  }

  const handleUpdatePatientBirthDate = () => {
    requestStatusRef.current?.startProgress('Updating...', 'secondary')
    API.updateCasePatientById({
      id,
      birthDate: encodeURIComponent(patientBirthDate),
    })
      .then(() => {
        getCase()
        requestStatusRef.current?.showAlert('Updated', 'success')
      })
      .catch((error: StandardErrorResponse) => {
        requestStatusRef.current?.showAlert(error.response.data.error, 'danger')
      })
  }

  return (
    <tr>
      <td>Patient</td>
      <td>
        <form>
          <input
            type="text"
            value={patientFirstName}
            className="mr-2 w-25"
            onChange={handleChangePatientFistName}
          />
          <input
            type="text"
            value={patientLastName}
            className="w-25"
            onChange={handleChangePatientLastName}
          />
          <button onClick={handleUpdatePatientName} className="float-right" type="button">
            Rename
          </button>
        </form>
        <form className="mt-2 border-top pt-2">
          <span className="mr-2 d-inline-block w-25">Birth date</span>
          <input
            type="date"
            value={patientBirthDate}
            className="w-25 mr-2"
            placeholder="YYYY-MM-DD"
            onChange={handleChangePatientBirthDate}
            max={Date.now()}
          />
          <button onClick={handleUpdatePatientBirthDate} className="float-right" type="button">
            Update birth date
          </button>
        </form>
        <form className="mt-2 border-top pt-2">
          <span className="mr-2 d-inline-block w-25">Gender</span>
          <select value={patientGender} onChange={handleChangePatientGender} className="w-25">
            {Object.entries(UserGender).map(([key, value], index) => (
              <option key={`${key}-${index}`} value={key}>
                {value}
              </option>
            ))}
          </select>
          <button onClick={handleUpdatePatientGender} className="float-right" type="button">
            Update gender
          </button>
        </form>
      </td>
    </tr>
  )
}
