import React from 'react'
import { Container } from 'reactstrap'

import { RequestStatus } from 'components/RequestStatus'
import { CreateAdminForm } from 'containers/UsersPage/components'

export const CreateAdminPage = () => {
  return (
    <Container className="w-50">
      <RequestStatus />
      <h2>Create Admin User</h2>
      <CreateAdminForm />
    </Container>
  )
}
