import React, { useContext, useRef, useState } from 'react'

import { API } from 'API'
import { RequestToasterContext } from 'containers/Providers'
import { SystemAccount } from 'domains/account'
import { saveAs } from 'file-saver'
import { StandardErrorResponse } from 'types/APIResponses'

interface AddressesFilesContentProps {
  account: SystemAccount
  onSearch: () => void
}

export const AddressesFilesContent = ({ account, onSearch }: AddressesFilesContentProps) => {
  const {
    username,
    account: { name },
  } = account
  const [importAddressesStatus, setImportAddressesStatus] = useState('')
  const fileInputRef = useRef<HTMLInputElement>(null)
  const { requestStatusRef } = useContext(RequestToasterContext)

  const handleExportAddresses = () => {
    requestStatusRef.current?.startProgress('Exporting file...', 'secondary')
    API.exportAccountAddress(username)
      .then((response) => {
        saveAs(new Blob([response]), name + '.xlsx')
        requestStatusRef.current?.showAlert('File exported', 'success')
      })
      .catch((error: StandardErrorResponse) => {
        requestStatusRef.current?.showAlert(error.response.data.error, 'danger')
      })
  }

  const handleImportAddresses = () => {
    const file = fileInputRef.current?.files?.item(0) ?? null

    if (!file) {
      alert('No file chosen')
      return
    }
    requestStatusRef.current?.startProgress('Importing file...', 'secondary')
    API.importAccountAddress({ username: account.username, file })
      .then((result) => {
        requestStatusRef.current?.showAlert('File imported', 'success')
        setImportAddressesStatus(result)
        onSearch()
      })
      .catch((error: StandardErrorResponse) => {
        requestStatusRef.current?.showAlert(error.response.data.error, 'danger')
      })
  }

  return (
    <div>
      <pre className="mt-4 small">{importAddressesStatus}</pre>
      <div className="w-75 border p-2 mt-4 bg-light small">
        <ol>
          <li className="mt-1">
            Export file with addresses{' '}
            <button className="small" onClick={handleExportAddresses}>
              Export
            </button>
          </li>
          <li className="mt-1">
            Edit file:
            <ul>
              <li>DO NOT modify "id" column</li>
              <li>Add rows that need to be added with empty "id" column</li>
              <li>Update rows that need to be updated</li>
              <li>To make address Inactive update "customer_status" to I</li>
              <li>To make address Active update "customer_status" to A</li>
              <li>Delete rows that need to be deleted (this is actual deletion from database)</li>
            </ul>
          </li>
          <li className="mt-1">
            Select edited file <input className="small" ref={fileInputRef} type="file" />
          </li>
          <li className="mt-1">
            DO NOT import empty excel file, since this will delete all addresses
          </li>
          <li className="mt-1">
            Import file{' '}
            <button className="small" onClick={handleImportAddresses}>
              Import
            </button>
          </li>
          <li className="mt-1">Verify correctness</li>
        </ol>
      </div>
    </div>
  )
}
