import { parseFileToForm } from 'utils/parseFileToFormData'

import { fileApi, protectedApi } from './requester'

export const getAccountsActiveAddresses = async (): Promise<Blob> => {
  return protectedApi
    .get(`/accounts/exportActiveAddresses`, {
      responseType: 'blob',
    })
    .then((response) => response.data)
}

export const importAccountsExternalAddressIds = async (file: File): Promise<string> => {
  return fileApi
    .post(`/accounts/importExternalAddressIds`, parseFileToForm(file))
    .then((response) => response.data)
}
