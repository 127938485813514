import { EmptyAPIResponse } from 'types/APIResponses'

import { protectedApi } from './requester'

export const getWebContent = () => {
  return protectedApi.get<string>('/webcontent').then((response) => response.data)
}

interface UpdateWebContentOptions {
  showMsg: boolean
  content?: string
}

export const updateWebContent = ({ showMsg, content }: UpdateWebContentOptions) => {
  return protectedApi.put<EmptyAPIResponse>(`/webcontent/${showMsg}`, content)
}
