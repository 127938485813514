import React, { useContext, useState } from 'react'

import { API } from 'API'
import { RequestToasterContext } from 'containers/Providers/RequestToasterProvider'
import { StandardErrorResponse } from 'types/APIResponses'

interface AccountFieldRepProps {
  username: string
  initFieldRep: string
}

export const AccountFieldRep = ({ username, initFieldRep }: AccountFieldRepProps) => {
  const [fieldRep, setFieldRep] = useState(initFieldRep)
  const handleChangeFieldRep = (event: React.ChangeEvent<HTMLInputElement>) =>
    setFieldRep(event.target.value)

  const { requestStatusRef } = useContext(RequestToasterContext)

  const handleUpdateFieldRep = () => {
    API.updateAccountParams({ username, fieldRep })
      .then(() => requestStatusRef.current?.showAlert('Updated', 'success'))
      .catch((error: StandardErrorResponse) => {
        requestStatusRef.current?.showAlert(error.response.data.error, 'danger')
      })
  }

  return (
    <tr>
      <td>Field Rep userid</td>
      <td>
        <form>
          <input
            name="fieldRep"
            onChange={handleChangeFieldRep}
            value={fieldRep}
            placeholder="Field Rep"
            className="w-75"
          />
          <button onClick={handleUpdateFieldRep} className="float-right" type="button">
            Update
          </button>
        </form>
      </td>
    </tr>
  )
}
