import { createContext } from 'react'

import { CasesPageState, initialCasesPageState } from './reducer'

interface CasesPageContextValues {
  casesPageState: CasesPageState
  getPhotos: () => void
  getCase: () => void
}

export const CasesPageContext = createContext<CasesPageContextValues>({
  casesPageState: initialCasesPageState,
  getCase: () => null,
  getPhotos: () => null,
})
