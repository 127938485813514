import { Treatment } from 'domains/treatment'
import { EmptyAPIResponse } from 'types/APIResponses'
import { Nullable } from 'types/utility'

import { protectedApi } from './requester'

export const getTreatment = async (treatmentId: number) => {
  return protectedApi.get<Treatment>(`/treatment/${treatmentId}`).then((response) => response.data)
}

interface UpdateTreatmentOptions {
  id: Nullable<number>
  alignerMaterial: string
}

export const updateTreatment = async ({ id, alignerMaterial }: UpdateTreatmentOptions) => {
  return protectedApi.put<EmptyAPIResponse>(`/treatment/${id}?alignerMaterial=${alignerMaterial}`)
}
