import React, { useContext } from 'react'

import { API } from 'API'
import { CasesPageContext } from 'containers/CasesPage/CasesPageContext'
import { RequestToasterContext } from 'containers/Providers'
import { Case } from 'domains/case'
import { StandardErrorResponse } from 'types/APIResponses'
import { isNotNil } from 'utils/isNotNil'

export const CaseInfo = () => {
  const { casesPageState, getCase } = useContext(CasesPageContext)
  const { id, originalCaseId, orderIndicator, reorder, erpSoEnabled } =
    casesPageState.userCase as Case
  const { requestStatusRef } = useContext(RequestToasterContext)

  const handleToggleErpSoEnabled = () => {
    API.toggleErp({
      id,
      erpSoEnabled: !erpSoEnabled,
    })
      .then(() => {
        getCase()
        requestStatusRef.current?.showAlert('Updated', 'success')
      })
      .catch((error: StandardErrorResponse) => {
        requestStatusRef.current?.showAlert(error.response.data.error, 'danger')
      })
  }

  return (
    <tr>
      <td>Case</td>
      <td>
        <span className="mr-1">Case ID: {id}</span>
        {isNotNil(originalCaseId) && (
          <span className="mr-1">| Original Case ID: {originalCaseId}</span>
        )}
        {isNotNil(orderIndicator) && (
          <span className="mr-1">| Order indicator: {orderIndicator}</span>
        )}
        {reorder && <span className="mr-1">| Reorder</span>}
        <span className="mr-1">
          | ERP enabled: {erpSoEnabled ? 'YES' : 'NO'}
          <button type="button" className="float-right" onClick={handleToggleErpSoEnabled}>
            Toggle ERP
          </button>
        </span>
      </td>
    </tr>
  )
}
