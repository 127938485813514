import React, { useContext, useRef, useState } from 'react'

import { API } from 'API'
import { RequestToasterContext } from 'containers/Providers'
import { StandardErrorResponse } from 'types/APIResponses'

interface ITeroUploadProps {
  onGetMatches: () => void
  onGetNotMatchedOrders: () => void
}

export const ITeroUploadSection = ({ onGetNotMatchedOrders, onGetMatches }: ITeroUploadProps) => {
  const [workOrderId, setWorkOrderId] = useState('')
  const handleChangeWorkOrderId = (event: React.ChangeEvent<HTMLInputElement>) =>
    setWorkOrderId(event.target.value)

  const { requestStatusRef } = useContext(RequestToasterContext)
  const fileInputRef = useRef<HTMLInputElement>(null)

  const handleUpload = () => {
    const file = fileInputRef.current?.files?.item(0) ?? null

    if (!file) {
      alert('No file chosen')
      return
    }

    requestStatusRef.current?.startProgress('Uploading file...', 'secondary')
    API.postITeroScans({ file, workOrderId })
      .then(() => {
        requestStatusRef.current?.showAlert('File uploaded', 'success')
        onGetMatches()
        onGetNotMatchedOrders()
      })
      .catch((err: StandardErrorResponse) => {
        const { error, status } = err.response.data

        requestStatusRef.current?.showAlert(
          status == 406 ? 'The file you provided already exists' : `${error}`,
          'danger',
        )
      })
  }

  return (
    <div id="upload" className="mt-4">
      <span className="mr-5">Upload scan ZIP</span>
      <input
        name="workOrderId"
        value={workOrderId}
        onChange={handleChangeWorkOrderId}
        placeholder="Work order ID"
        className="w-25 mr-2"
      />
      <input ref={fileInputRef} type="file" />
      <button className="mr-1" onClick={handleUpload}>
        Upload
      </button>
    </div>
  )
}
