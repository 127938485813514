import { useState, useEffect } from 'react'

import { API } from 'API'

const Version = () => {
  const [version, setVersion] = useState('')
  const [timestamp, setTimestamp] = useState('')

  useEffect(() => {
    API.getVersion()
      .then((response) => {
        setVersion(response.version)
        setTimestamp(response.timestamp)
      })
      .catch(console.error)
  }, [])

  return (
    <span className="text-muted float-right">
      Version {version} built on {timestamp}
    </span>
  )
}

export default Version
