import { ActiveDirectoryURLs } from 'domains/auth'

interface LoginSSOErrorProps {
  error: string
  condition: string
  activeDirectoryURLs: ActiveDirectoryURLs
}

export const LoginSSOError = ({ error, condition, activeDirectoryURLs }: LoginSSOErrorProps) => {
  return (
    <>
      <p className="d-block mt-5 font-weight-bold text-danger">{error}</p>
      <p>
        {condition}, you can submit a&nbsp;
        <a
          href={activeDirectoryURLs.ticket}
          className="font-weight-bold text-decoration-none text-dark">
          Support Ticket
        </a>
        &nbsp;or download the&nbsp;
        <a
          href={activeDirectoryURLs.PDFGuide}
          className="font-weight-bold text-decoration-none text-dark">
          User Guide
        </a>
        &nbsp;for further assistance
      </p>
    </>
  )
}
