import { FC } from 'react'

import { PermissionsFormItem } from './common'

const applicationTypes = ['Anatomy', 'Approver', 'Builder', 'Designer', 'Web']

interface PermissionsFilterProps {
  application: string
  onApplicationChanged: (value: string) => void
  hideNullish: boolean
  onHideNullishChanged: (value: boolean) => void
  filterQuery: string
  onFilterQueryChanged: (value: string) => void
}
export const PermissionsFilter: FC<PermissionsFilterProps> = ({
  application,
  onApplicationChanged,
  hideNullish,
  onHideNullishChanged,
  filterQuery,
  onFilterQueryChanged,
}) => {
  return (
    <div className="w-25 pl-2" style={{ position: 'sticky', top: 8 }}>
      <div className="border p-3 bg-light" style={{ width: 224 }}>
        <PermissionsFormItem
          name="Filter"
          value={
            <input
              id="filterQuery"
              type="text"
              value={filterQuery}
              onChange={(event) => onFilterQueryChanged(event.target.value)}
            />
          }
        />
        <PermissionsFormItem
          name="Application"
          value={
            <select
              name="applicationType"
              id="applicationType"
              value={application}
              onChange={(event) => onApplicationChanged(event.target.value)}>
              {applicationTypes.map((appType) => (
                <option key={appType} value={appType}>
                  {appType}
                </option>
              ))}
            </select>
          }
        />
        <div>
          <input
            id="hideNullish"
            type="checkbox"
            className="mr-1"
            checked={hideNullish}
            onChange={(event) => onHideNullishChanged(event.target.checked)}
          />
          <label htmlFor="hideNullish">Hide false values</label>
        </div>
      </div>
    </div>
  )
}
