export const rolesMap = {
  ROLE_USER_ADMIN: 'admin',
  ROLE_OPERATOR: 'operator',
  ROLE_NORMAL: 'doctor',
  ROLE_DIGITAL_REP: 'ROLE_DIGITAL_REP',
  ROLE_FIELD_REP: 'ROLE_FIELD_REP',
} as const

export const operatorRolesMap = {
  ROLE_SCANNER: 'scanner',
  ROLE_ANATOMY: 'anatomy',
  ROLE_DESIGNER: 'designer',
  ROLE_DESIGN_QC: 'designqc',
  ROLE_SHIPPER: 'shipper',
  ROLE_MANUFACTURING: 'manufacturing',
} as const
