import React, { Reducer, useContext, useEffect, useReducer } from 'react'
import { Container, ListGroup, ListGroupItem } from 'reactstrap'

import { API } from 'API'
import { RequestToasterContext } from 'containers/Providers'
import { StandardErrorResponse } from 'types/APIResponses'
import { isNotNil } from 'utils/isNotNil'

import { ScannersReducerActions, ScanPayload } from './actions'
import { ScannerItem, ScannerItemActions } from './components'
import { initialScannersState, scannersPageReducer, ScannersPageState } from './reducer'

export const ScannersPage = () => {
  const { requestStatusRef } = useContext(RequestToasterContext)
  const [scannersState, dispatchToScannersState] = useReducer<
    Reducer<ScannersPageState, ScannersReducerActions>
  >(scannersPageReducer, initialScannersState)

  const createDispatchEvent = (id?: number) => (payload: ScanPayload) =>
    isNotNil(id)
      ? dispatchToScannersState({
          type: 'setListScanInput',
          payload: { id, value: payload },
        })
      : dispatchToScannersState({
          type: 'setNewScanInput',
          payload,
        })

  useEffect(() => {
    handleList()
  }, [])

  const handleList = () => {
    API.getScanners()
      .then((response) => {
        dispatchToScannersState({ type: 'setScannersResponse', payload: response })
      })
      .catch((error: StandardErrorResponse) => {
        requestStatusRef.current?.showAlert(error.response.data.error, 'danger')
      })
  }

  const handleInsert = () => {
    requestStatusRef.current?.startProgress('Inserting...', 'secondary')
    API.postNewScanner(scannersState.newScanner)
      .then(() => {
        requestStatusRef.current?.showAlert('Inserted', 'success')
        handleList()
      })
      .catch((error: StandardErrorResponse) => {
        requestStatusRef.current?.showAlert(error.response.data.error, 'danger')
      })
  }

  return (
    <Container>
      <h2>Scanners</h2>
      <ListGroup>
        <ListGroupItem key="new">
          <ScannerItem scanner={scannersState.newScanner} dispatchToState={createDispatchEvent()} />
          <button onClick={handleInsert} className="float-right mr-2" type="button">
            Insert
          </button>
        </ListGroupItem>
        {scannersState.scanners.map((scanner, index) => (
          <ListGroupItem key={`${scanner.id}-${index}`}>
            <ScannerItem scanner={scanner} dispatchToState={createDispatchEvent(scanner.id)} />
            <ScannerItemActions scanner={scanner} getScanners={handleList} />
          </ListGroupItem>
        ))}
      </ListGroup>
    </Container>
  )
}
