import { Table } from 'reactstrap'

import { SystemAccount } from 'domains/account'

interface AddressAccountInfoTableProps {
  userAccount: SystemAccount
}

export const AddressAccountInfoTable = ({ userAccount }: AddressAccountInfoTableProps) => {
  const {
    firstName,
    lastName,
    username,
    account: { name, erp, opcoId, customerId },
  } = userAccount

  return (
    <Table bordered className="w-75">
      <tbody>
        <tr>
          <td>Doctor</td>
          <td>
            {firstName} {lastName} | {username}
          </td>
        </tr>
        <tr>
          <td>Account</td>
          <td>{name}</td>
        </tr>
        <tr>
          <td>ERP | Opco | Customer</td>
          <td>
            {erp ?? '-'} | {opcoId ?? '-'} | {customerId ?? '-'}
          </td>
        </tr>
      </tbody>
    </Table>
  )
}
