import { Support } from 'domains/support'
import { EmptyAPIResponse } from 'types/APIResponses'

import { protectedApi } from './requester'

export const getSupports = () => {
  return protectedApi.get<Support[]>(`/supports`).then((response) => response.data)
}

export const updateSupport = ({ region, ...params }: Support) => {
  return protectedApi.put<EmptyAPIResponse>(`/support/${region}`, {}, { params })
}
