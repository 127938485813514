import React, { useContext } from 'react'
import { Table } from 'reactstrap'

import { API } from 'API'
import { RequestToasterContext } from 'containers/Providers'
import { ITeroCase } from 'domains/itero'
import { StandardErrorResponse } from 'types/APIResponses'
import { isNotNil } from 'utils/isNotNil'

const DAY_DURATION_MS = 1000 * 60 * 60 * 24

const iTeroNewTableHeaders = [
  'CaseID',
  'I',
  'Patient',
  'ITero Patient',
  '%',
  'Doctor',
  'iTero Doctor',
  '%',
  'Days Old',
  'Hold status',
  'To Be Cleaned',
  'To Be Verified',
  ' ',
] as const

const getTableClassName = ({ toBeProcessedDate, toBeVerifiedDate, iteroPatient }: ITeroCase) => {
  if (isNotNil(toBeProcessedDate)) {
    return 'success'
  }

  if (isNotNil(toBeVerifiedDate)) {
    return 'warning'
  }

  if (isNotNil(iteroPatient)) {
    return 'attention'
  }

  return 'danger'
}

interface ITeroNewTableProps {
  searchString: string
  onChangeSearch: (event: React.ChangeEvent<HTMLInputElement>) => void
  matches: ITeroCase[]
  getMatches: () => void
  getDoctors: () => void
}

export const ITeroNewTable = ({
  searchString,
  onChangeSearch,
  matches,
  getMatches,
  getDoctors,
}: ITeroNewTableProps) => {
  const { requestStatusRef } = useContext(RequestToasterContext)

  const handleVerify = (item: ITeroCase) => {
    const { iteroDoctor, orderId, userId, doctor } = item

    if (
      !window.confirm(
        String('Confirm that Spark doctor:\n * ' + doctor + '\nUses iTero as:\n * ' + iteroDoctor),
      )
    ) {
      return
    }
    requestStatusRef.current?.startProgress('Updating...', 'secondary')
    API.postDoctorITeroOrder({ iteroDoctor: iteroDoctor as string, orderId, userId })
      .then(() => {
        getMatches()
        getDoctors()
        requestStatusRef.current?.showAlert('Updated', 'success')
      })
      .catch((error: StandardErrorResponse) => {
        requestStatusRef.current?.showAlert(error.response.data.error, 'danger')
      })
  }

  const shouldShowTable = (iTeroCase: ITeroCase) => {
    const comparableString = searchString.toLowerCase()

    return (
      !searchString ||
      iTeroCase.caseId.toString().indexOf(comparableString) >= 0 ||
      iTeroCase.patient.toLowerCase().indexOf(comparableString) >= 0 ||
      iTeroCase.doctor.toLowerCase().indexOf(comparableString) >= 0 ||
      (isNotNil(iTeroCase.iteroPatient) &&
        iTeroCase.iteroPatient.toLowerCase().indexOf(comparableString) >= 0) ||
      (isNotNil(iTeroCase.iteroDoctor) &&
        iTeroCase.iteroDoctor.toLowerCase().indexOf(comparableString) >= 0)
    )
  }

  return (
    <div id="new">
      <h4>iTero scan cases in NEW status</h4>
      <h6>Color legend</h6>
      <ul>
        <li className="small m-2 p-2 success">
          Files copied successfully, need manual impression received
        </li>
        <li className="small m-2 p-2 attention">Patient matched, pending files download</li>
        <li className="small m-2 p-2 warning">
          Match is below threshold. Needs manual verification
        </li>
        <li className="small m-2 p-2 danger">Scan not available yet</li>
      </ul>
      <hr />
      <input
        value={searchString}
        name="searchCases"
        placeholder="search"
        className="m-2 w-25"
        onChange={onChangeSearch}
      />
      <Table size="small">
        <thead>
          <tr>
            {iTeroNewTableHeaders.map((title, index) => (
              <th className="small font-weight-bold" key={`${title}-${index}`}>
                {title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {matches.map(
            (item, index: number) =>
              shouldShowTable(item) && (
                <tr key={index} className={getTableClassName(item)}>
                  <td className="small">{item.caseId}</td>
                  <td className="small">{item.orderIndicator}</td>
                  <td className="small">{item.patient}</td>
                  <td className="small">{item.iteroPatient}</td>
                  <td className="small">{item.patientMatch}</td>
                  <td className="small">{item.doctor}</td>
                  <td className="small">{item.iteroDoctor}</td>
                  <td className="small">{item.doctorMatch}</td>
                  <td className="small text-right">
                    {((Date.now() - item.caseSubmitted) / DAY_DURATION_MS).toFixed(2)}
                  </td>
                  <td className="small">{item.onHold ? 'On Hold' : ''}</td>
                  <td className="small">
                    {isNotNil(item.toBeProcessedDate) &&
                      new Date(item.toBeProcessedDate).toISOString().slice(0, 19).replace('T', ' ')}
                  </td>
                  <td className="small">
                    {isNotNil(item.toBeVerifiedDate) &&
                      new Date(item.toBeVerifiedDate).toISOString().slice(0, 19).replace('T', ' ')}
                  </td>
                  <td>
                    {item.verifyDoctor && (
                      <button onClick={() => handleVerify(item)}>Verify</button>
                    )}
                  </td>
                </tr>
              ),
          )}
        </tbody>
      </Table>
    </div>
  )
}
