import { FC } from 'react'

import { FeatureFlagValue } from 'domains/featureFlags'

interface PermissionValueProps {
  rawValue: FeatureFlagValue | FeatureFlagValue[]
}
export const PermissionValue: FC<PermissionValueProps> = ({ rawValue }) => {
  if (['string', 'number'].includes(typeof rawValue)) {
    return <span>{rawValue.toString()}</span>
  }

  if (['boolean'].includes(typeof rawValue)) {
    return <input type="checkbox" readOnly checked={rawValue as unknown as boolean} />
  }

  if (Array.isArray(rawValue)) {
    return (
      <>
        {rawValue.map((ffItem) => (
          <li key={ffItem.toString()}>{ffItem}</li>
        ))}
      </>
    )
  }

  return <span style={{ color: 'red' }}>Unexpected type of the permission</span>
}
