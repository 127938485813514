import React from 'react'
import { Table } from 'reactstrap'

import { ITeroNotMatchedOrder } from 'domains/itero'

const notMatchedOrdersTableHeaders = [
  'OrderID',
  'OrderCode',
  'Patient',
  'Doctor',
  'ExportTime',
] as const

interface ITeroNoMatchOrdersTableProps {
  notMatchedOrders: ITeroNotMatchedOrder[]
  notMatchedLimit: string
  onChangeNotMatchedLimit: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export const ITeroNoMatchOrdersTable = ({
  notMatchedOrders,
  notMatchedLimit,
  onChangeNotMatchedLimit,
}: ITeroNoMatchOrdersTableProps) => {
  return (
    <div id="no-match">
      <h4>
        <input
          size={3}
          name="notMatchedLimit"
          value={notMatchedLimit}
          onChange={onChangeNotMatchedLimit}
        />{' '}
        most recent iTero scans - no match
      </h4>
      <Table size="small">
        <thead>
          <tr>
            {notMatchedOrdersTableHeaders.map((title, index) => (
              <th className="small font-weight-bold" key={`${title}-${index}`}>
                {title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {notMatchedOrders.map((item, index) => (
            <tr key={`${index}-${item.orderID}`}>
              <td className="small">{item.orderID}</td>
              <td className="small">{item.orderCode}</td>
              <td className="small">{item.patient}</td>
              <td className="small">{item.doctor}</td>
              <td className="small">
                {new Date(item.exportTime).toISOString().slice(0, 19).replace('T', ' ')}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}
