export const UserSearchHint = () => {
  return (
    <div
      className="w-auto border p-2 mt-4 bg-light small"
      style={{ position: 'absolute', top: '5em', right: '1em' }}>
      <h6>By name:</h6>
      <ul>
        <li>{'<name>'}</li>
        <li>{'<first> <last>'}</li>
      </ul>
      <h6>By role and name:</h6>
      <ul>
        <li>{'<role> <name>'}</li>
      </ul>
      <h6>Roles:</h6>
      <ul>
        <li>admin</li>
        <li>doctor</li>
        <li>operator</li>
        <li>scanner, scan</li>
        <li>anatomy</li>
        <li>designer, design</li>
        <li>designqc, qc</li>
        <li>shipper, ship</li>
        <li>manufacturing, mfg</li>
      </ul>
    </div>
  )
}
