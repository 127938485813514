import { FC } from 'react'

interface PermissionsFormItemProps {
  name: string
  value?: React.ReactNode
}

const defaultValue = 'None'

export const PermissionsFormItem: FC<PermissionsFormItemProps> = ({ name, value }) => {
  return (
    <div>
      <label htmlFor={name} style={{ fontSize: 11, textTransform: 'uppercase' }}>
        {name}
      </label>
      <p id={name}>{value ?? defaultValue}</p>
    </div>
  )
}
