import { FC } from 'react'

interface PermissionsGroupHeaderProps {
  level: number
  name: string
  isOpen: boolean
  toggleHiddenPart: () => void
}
export const PermissionsGroupHeader: FC<PermissionsGroupHeaderProps> = ({
  level,
  name,
  isOpen,
  toggleHiddenPart,
}) => {
  const backgroundAccordingToLevel = '#000000' + ('0' + 8 * level).slice(-2)
  const levelBackgroundStyle = { backgroundColor: backgroundAccordingToLevel }
  const levelPaddingStyle = { paddingLeft: 12 + level * 16, cursor: 'pointer' }

  return (
    <tr onClick={toggleHiddenPart} style={levelBackgroundStyle}>
      <td className="w-50" style={levelPaddingStyle} colSpan={2}>
        <span style={{ display: 'inline-block', width: 16 }}>{isOpen ? '🞃' : '🞂'}</span>
        <span>{name}</span>
      </td>
    </tr>
  )
}
