import { useFormContext } from 'react-hook-form'

import {
  FeatureFlagsSchemaEntry,
  FeatureFlagValue,
  GenericFeatureFlags,
} from 'domains/featureFlags'

import { FeatureFlagArrayItem, FFListItemType } from './FeatureFlagArrayItem'
import { FeatureFlagBooleanItem } from './FeatureFlagBooleanItem'
import { FeatureFlagInputItem } from './FeatureFlagInputItem'

interface FeatureFlagControlProps {
  path: string
  ffSchemaItem: FeatureFlagsSchemaEntry
  dbFFItem?: FeatureFlagValue | FeatureFlagValue[]
}

export const FeatureFlagControl = ({ path, ffSchemaItem, dbFFItem }: FeatureFlagControlProps) => {
  const form = useFormContext<GenericFeatureFlags>()
  const value = form.watch(path)

  const onChange = (nevValue: FeatureFlagValue | FeatureFlagValue[]) => {
    form.setValue(path, nevValue)

    for (const syncronizedFFKey of ffSchemaItem.sync ?? []) {
      form.setValue(syncronizedFFKey, nevValue)
    }
  }

  if (ffSchemaItem.type === 'boolean') {
    return (
      <FeatureFlagBooleanItem
        title={ffSchemaItem.title}
        disabled={ffSchemaItem.readOnly}
        value={(value ?? false) as boolean}
        onChange={onChange}
      />
    )
  }

  if (ffSchemaItem.type === 'number' || ffSchemaItem.type === 'string') {
    const inputType = ffSchemaItem.type === 'number' ? 'number' : 'text'

    return (
      <FeatureFlagInputItem
        title={ffSchemaItem.title}
        inputType={inputType}
        disabled={ffSchemaItem.readOnly}
        value={value as string | number}
        onChange={onChange}
      />
    )
  }

  if (ffSchemaItem.type === 'array') {
    return (
      <FeatureFlagArrayItem
        title={ffSchemaItem.title}
        value={value as FFListItemType[]}
        lockedValues={dbFFItem as FFListItemType[]}
        onChange={onChange}
        options={ffSchemaItem.items?.enum ?? []}
        displayValues={ffSchemaItem.displayValue}
        disabled={ffSchemaItem.readOnly}
      />
    )
  }

  return (
    <span style={{ color: 'red' }}>
      Unexpected feature flag type for {ffSchemaItem.title} ({ffSchemaItem.type}).
    </span>
  )
}
