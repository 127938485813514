import axios from 'axios'

import { getBaseUrl, getDefaultHeaders, getProtectedHeaders } from './middleware'

export const protectedApi = axios.create()

protectedApi.interceptors.request.use(
  async (config) => getBaseUrl(config).then(getDefaultHeaders).then(getProtectedHeaders),
  (error) => Promise.reject(error),
)
