import { DEFAULT_NUMBER_TYPE_VALUE } from 'constants/common'
import { photoLabels } from 'constants/photoLables'
import { Address } from 'domains/address'
import { Case, CasePhoto, CaseStatusRollback } from 'domains/case'
import { ShippingProviders } from 'domains/shippingProviders'
import { Treatment } from 'domains/treatment'
import { Nullable } from 'types/utility'

import { CasesPageActions } from './actions'

export interface CasesPageState {
  userCase: Nullable<Case>
  rollback: Nullable<CaseStatusRollback>
  treatment: Treatment
  alignerMaterials: string[]
  shipAddressData: Address[]
  photos: Nullable<Record<keyof typeof photoLabels, CasePhoto>>
  printCaseIdData: Case[]
  isRollbackDialogOpen: boolean
  shipAddressId: number
  billAddressId: number
  casePrintId: number
  shippingProviders: ShippingProviders[]
}

export const CasesPageReducer = (
  state: CasesPageState,
  action: CasesPageActions,
): CasesPageState => {
  switch (action.type) {
    case 'setTreatment':
      return { ...state, treatment: action.payload }
    case 'setUserCase':
      return { ...state, userCase: action.payload }
    case 'setAlignerMaterials':
      return { ...state, alignerMaterials: action.payload }
    case 'setRollback':
      return { ...state, rollback: action.payload }
    case 'setAddressData':
      return { ...state, shipAddressData: action.payload }
    case 'setCasePhotos':
      return { ...state, photos: action.payload }
    case 'setCasePrintId':
      return { ...state, casePrintId: action.payload }
    case 'setPrintCaseIdData':
      return { ...state, printCaseIdData: action.payload }
    case 'setBillAddressId':
      return { ...state, billAddressId: action.payload }
    case 'setShipAddressId':
      return { ...state, shipAddressId: action.payload }
    case 'setShippingProviders':
      return { ...state, shippingProviders: action.payload }
    default:
      return state
  }
}

export const initialCasesPageState: CasesPageState = {
  treatment: {
    id: null,
    treatArches: null,
    alignerMaterial: null,
  },
  alignerMaterials: [],
  shipAddressData: [],
  photos: null,
  printCaseIdData: [],
  isRollbackDialogOpen: false,
  userCase: null,
  rollback: null,
  shipAddressId: DEFAULT_NUMBER_TYPE_VALUE,
  billAddressId: DEFAULT_NUMBER_TYPE_VALUE,
  casePrintId: DEFAULT_NUMBER_TYPE_VALUE,
  shippingProviders: [],
}
