import React from 'react'

import { App } from 'containers/App'
import { createRoot } from 'react-dom/client'
import 'bootstrap/dist/css/bootstrap.min.css'
import './index.css'
import 'rc-tooltip/assets/bootstrap_white.css'

const container = document.getElementById('root')
const root = createRoot(container as HTMLInputElement)

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)
