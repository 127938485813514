import React, { useContext, useEffect, useState } from 'react'

import { API } from 'API'
import { CasesPageContext } from 'containers/CasesPage/CasesPageContext'
import { RequestToasterContext } from 'containers/Providers'
import { Case, CaseStatusMatrix } from 'domains/case'
import { StandardErrorResponse } from 'types/APIResponses'

import { AddressSelect } from './AddressSelect'

export const CaseShippingAddresses = () => {
  const { casesPageState } = useContext(CasesPageContext)
  const { shipAddressId: initialShipAddressId, billAddressId: initialBillAddressId } =
    casesPageState
  const { id, caseStatus, cancelled } = casesPageState.userCase as Case
  const { requestStatusRef } = useContext(RequestToasterContext)

  const [shipAddressId, setShipAddressId] = useState(initialShipAddressId)
  const handleChangeShipAddressId = (event: React.ChangeEvent<HTMLSelectElement>) =>
    setShipAddressId(Number(event.target.value))

  const [billAddressId, setBillAddressId] = useState(initialBillAddressId)
  const handleChangeBillAddressId = (event: React.ChangeEvent<HTMLSelectElement>) =>
    setBillAddressId(Number(event.target.value))

  useEffect(() => {
    setShipAddressId(initialShipAddressId)
    setBillAddressId(initialBillAddressId)
  }, [initialBillAddressId, initialShipAddressId])

  const handleUpdateShipAddress = () => {
    API.updateCaseById({ id, shipAddressId })
      .then(() => {
        requestStatusRef.current?.showAlert('Updated', 'success')
      })
      .catch((error: StandardErrorResponse) => {
        requestStatusRef.current?.showAlert(error.response.data.error, 'danger')
      })
  }

  const handleUpdateBillAddress = () => {
    API.updateCaseById({ id, billAddressId })
      .then(() => {
        requestStatusRef.current?.showAlert('Updated', 'success')
      })
      .catch((error: StandardErrorResponse) => {
        requestStatusRef.current?.showAlert(error.response.data.error, 'danger')
      })
  }

  const isSectionDisabled = caseStatus === CaseStatusMatrix.SHIPPED || cancelled

  return (
    <>
      <tr>
        <td>Shipping Address</td>
        <td>
          <AddressSelect
            selectName="shipAddressId"
            addressId={shipAddressId}
            changeAddressId={handleChangeShipAddressId}
          />
          <button
            disabled={isSectionDisabled}
            onClick={handleUpdateShipAddress}
            className="float-right"
            type="button">
            Update
          </button>
        </td>
      </tr>
      <tr>
        <td>Billing Address</td>
        <td>
          <AddressSelect
            selectName="billAddressId"
            addressId={billAddressId}
            changeAddressId={handleChangeBillAddressId}
          />
          <button
            disabled={isSectionDisabled}
            onClick={handleUpdateBillAddress}
            className="float-right"
            type="button">
            Update
          </button>
        </td>
      </tr>
    </>
  )
}
