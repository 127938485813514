import React, { useEffect } from 'react'

import { Layout } from 'containers/Layout'
import { history } from 'core/history'

import { HistoryProvider, RequestToasterProvider } from '../Providers'

/**
 * Providers should be initialized here
 */

export const App = () => {
  useEffect(() => {
    const regions = Object.keys(window.env('REGIONS'))
    const hasRegion = regions.some((region) => region === window.env('REGION'))

    if (!hasRegion) {
      window.setRegion(regions[0])
      window.location.reload()
    }
  }, [])

  return (
    <HistoryProvider history={history}>
      <RequestToasterProvider>
        <Layout />
      </RequestToasterProvider>
    </HistoryProvider>
  )
}
