import { AxiosRequestConfig } from 'axios'
import { AppRoutes } from 'constants/appRoutes'
import { getToken, isLoggedIn, logout } from 'core/auth'
import { history } from 'core/history'

export const getProtectedHeaders = async (config: AxiosRequestConfig) => {
  const token = getToken()

  if (isLoggedIn()) {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${token}`,
    }
  } else {
    logout()
    history.push(AppRoutes.login)
  }

  return config
}
