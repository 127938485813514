import { GenericFeatureFlags } from 'domains/featureFlags'

export const filterObjectByNullishValues = (obj: GenericFeatureFlags) => {
  if (typeof obj !== 'object' || obj === null) {
    return obj
  }

  const result: GenericFeatureFlags = {}

  return Object.keys(obj).reduce((acc, key) => {
    const value = obj[key]

    const IsFFEnabled =
      (typeof value == 'boolean' && value) ||
      (typeof value == 'string' && value.length > 0) ||
      (Array.isArray(value) && value.length > 0) ||
      typeof value == 'number'

    if (IsFFEnabled) {
      acc[key] = obj[key]
    }

    if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
      const subObject = filterObjectByNullishValues(obj[key] as GenericFeatureFlags)

      if (Object.keys(subObject).length) {
        acc[key] = subObject
      }
    }

    return acc
  }, result)
}

export const filterObjectByKey = (obj: GenericFeatureFlags, keyToKeep: string) => {
  if (typeof obj !== 'object' || obj === null) {
    return obj
  }

  const result: GenericFeatureFlags = {}

  return Object.keys(obj).reduce((acc, key) => {
    if (key.toLowerCase().includes(keyToKeep)) {
      acc[key] = obj[key]
    }

    if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
      const subObject = filterObjectByKey(obj[key] as GenericFeatureFlags, keyToKeep)

      if (Object.keys(subObject).length) {
        acc[key] = subObject
      }
    }

    return acc
  }, result)
}
