import React from 'react'
import { ListGroup, ListGroupItem, Table } from 'reactstrap'

import { Case, CaseScan } from 'domains/case'
import { ProductInfo } from 'domains/productInfo'
import { TreatArches, Treatment } from 'domains/treatment'
import BigSpinner from 'resources/img/spinner-big.gif'
import { Nullable } from 'types/utility'
import { isNotNil } from 'utils/isNotNil'

interface ScansPageContentProps {
  treatmentCase: Case
  treatment: Nullable<Treatment>
  scans: CaseScan[]
}

export const ScansPageContent = ({ treatmentCase, treatment, scans }: ScansPageContentProps) => {
  const {
    id,
    originalCaseId,
    orderIndicator,
    reorder,
    caseDate,
    caseStatus,
    doctor,
    patient,
    productInfoId,
    cancelled,
    impressionType,
  } = treatmentCase

  const isOdb = Boolean(productInfoId) && productInfoId == ProductInfo.ODB

  return (
    <Table bordered className="w-75">
      <tbody>
        <tr>
          <td>Case</td>
          <td>
            <span className="mr-1">Case ID: {id}</span>
            {isNotNil(originalCaseId) && (
              <span className="mr-1">| Original Case ID: {originalCaseId}</span>
            )}
            {isNotNil(orderIndicator) && (
              <span className="mr-1">| Order indicator: {orderIndicator}</span>
            )}
            {reorder && <span className="mr-1">| Reorder</span>}
          </td>
        </tr>
        <tr>
          <td>Case date</td>
          <td>{new Date(caseDate).toISOString().slice(0, 10)}</td>
        </tr>
        <tr>
          <td>Case status</td>
          <td>{caseStatus}</td>
        </tr>
        <tr>
          <td>Doctor</td>
          <td>{doctor.username}</td>
        </tr>
        <tr>
          <td>Patient</td>
          <td>
            <span className="mr-1">
              {patient.personFirstName} {patient.personLastName}
            </span>
            <span className="mr-1">| {patient.personBirthDate}</span>
            <span className="mr-1">| {patient.personGender}</span>
          </td>
        </tr>
        <tr>
          <td>Product</td>
          <td>
            {isNotNil(productInfoId) && (
              <select name="productInfoId" disabled value={productInfoId} className="w-50">
                {Object.entries(ProductInfo).map(([key, value]) => (
                  <option key={key} value={value}>
                    {key}
                  </option>
                ))}
              </select>
            )}
          </td>
        </tr>
        <tr>
          <td>Cancelled</td>
          <td>
            <select name="cancelled" disabled value={String(cancelled)} className="w-50">
              <option value="true">yes</option>
              <option value="false">no</option>
            </select>
          </td>
        </tr>
        {treatment && treatment.treatArches && !isOdb && (
          <tr>
            <td>Treat arches</td>
            <td>
              <select disabled value={treatment.treatArches} className="w-50">
                {Object.entries(TreatArches).map(([key, value]) => (
                  <option key={value} value={key}>
                    {value}
                  </option>
                ))}
              </select>
            </td>
          </tr>
        )}
        {isNotNil(scans.length) && (
          <tr>
            <td>Scans</td>
            <td>
              <div className="m-1">Scanner Type: {impressionType}</div>
              <ListGroup>
                {scans.map((item: CaseScan, index: number) =>
                  item.id === 0 ? (
                    <ListGroupItem key={index} className="p-0">
                      <img src={BigSpinner} height="50px" alt="spinner" />
                    </ListGroupItem>
                  ) : (
                    <ListGroupItem key={index}>
                      <a href={item.url}>{item.fileName}</a>
                    </ListGroupItem>
                  ),
                )}
              </ListGroup>
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  )
}
