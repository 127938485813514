import { ITeroDoctor, ITeroCase, ITeroNotMatchedOrder } from 'domains/itero'
import { EmptyAPIResponse } from 'types/APIResponses'
import { parseFileToForm } from 'utils/parseFileToFormData'

import { protectedApi, fileApi } from './requester'

export const getDoctorsITero = async () => {
  return protectedApi.get<ITeroDoctor[]>('/itero/doctors').then((response) => response.data)
}

export const deleteDoctorITero = async (id: number) => {
  return protectedApi.delete<EmptyAPIResponse>(`/itero/doctor/${id}`)
}

interface PostITeroOrderOptions {
  userId: string
  iteroDoctor: string
  orderId: string
}

export const postDoctorITeroOrder = async ({
  userId,
  iteroDoctor,
  orderId,
}: PostITeroOrderOptions) => {
  return protectedApi.post<EmptyAPIResponse>(`/itero/doctor/${userId}`, null, {
    params: {
      doctor: encodeURIComponent(iteroDoctor),
      orderId,
    },
  })
}

export const getITeroMatches = async () => {
  return protectedApi.get<ITeroCase[]>('/itero/matches').then((response) => response.data)
}

export const getITeroNotMatchedOrders = async (limit: string) => {
  return protectedApi
    .get<ITeroNotMatchedOrder[]>(`/itero/not-matched?limit=${limit}`)
    .then((response) => response.data)
}

interface PostITeroScansOptions {
  workOrderId: string
  file: File
}

export const postITeroScans = async ({ workOrderId, file }: PostITeroScansOptions) => {
  return fileApi
    .post<EmptyAPIResponse>(`/itero/upload?workOrderId=${workOrderId}`, parseFileToForm(file))
    .then((response) => response.data)
}
