import { AxiosRequestConfig } from 'axios'

export const getDefaultHeaders = async (config: AxiosRequestConfig) => {
  config.headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ...config.headers,
  }

  return config
}
