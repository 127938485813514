import React, { Reducer, useContext, useReducer } from 'react'
import { ListGroupItem } from 'reactstrap'

import { API } from 'API'
import { RequestToasterContext } from 'containers/Providers'
import { Support } from 'domains/support'
import { StandardErrorResponse } from 'types/APIResponses'

import { SetSupportItemInputAction } from './actions'
import { supportItemReducer } from './reducer'

interface SupportItemProps {
  support: Support
  getSupports: () => void
}

export const SupportItem = ({ support, getSupports }: SupportItemProps) => {
  const { requestStatusRef } = useContext(RequestToasterContext)
  const [supportState, dispatchToSupportState] = useReducer<
    Reducer<Support, SetSupportItemInputAction>
  >(supportItemReducer, support)
  const {
    region,
    name,
    email,
    phone,
    fax,
    address1,
    address2,
    city,
    state,
    country,
    version,
    zip,
  } = supportState

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    dispatchToSupportState({
      type: 'setSupportInputValue',
      payload: { [event.target.name]: event.target.value },
    })

  const handleUpdate = () => {
    requestStatusRef.current?.startProgress('Updating...', 'secondary')
    API.updateSupport(supportState)
      .then(() => {
        requestStatusRef.current?.showAlert('Updated', 'success')
        getSupports()
      })
      .catch((error: StandardErrorResponse) => {
        requestStatusRef.current?.showAlert(error.response.data.error, 'danger')
      })
  }

  return (
    <ListGroupItem>
      <button onClick={handleUpdate} className="float-right m-1" type="button">
        Update
      </button>
      <h4 className="m-1">{region}</h4>
      <div className="m-1 w-25 d-inline-block">Name</div>
      <input type="text" name="name" className="m-1 w-50" value={name} onChange={handleChange} />
      <div className="m-1 w-25 d-inline-block">Email</div>
      <input type="text" name="email" className="m-1 w-50" value={email} onChange={handleChange} />
      <div className="m-1 w-25 d-inline-block">Phone</div>
      <input type="text" name="phone" className="m-1 w-50" value={phone} onChange={handleChange} />
      <div className="m-1 w-25 d-inline-block">Fax</div>
      <input
        type="text"
        name="fax"
        className="m-1 w-50"
        value={fax ?? ''}
        onChange={handleChange}
      />
      <div className="m-1 w-25 d-inline-block">Address line 1</div>
      <input
        type="text"
        name="address1"
        className="m-1 w-50"
        value={address1}
        onChange={handleChange}
      />
      <div className="m-1 w-25 d-inline-block">Address line 2</div>
      <input
        type="text"
        name="address2"
        className="m-1 w-50"
        value={address2 ?? ''}
        onChange={handleChange}
      />
      <div className="m-1 w-25 d-inline-block">City</div>
      <input type="text" name="city" className="m-1 w-50" value={city} onChange={handleChange} />
      <div className="m-1 w-25 d-inline-block">State</div>
      <input
        type="text"
        name="state"
        className="m-1 w-50"
        value={state ?? ''}
        onChange={handleChange}
      />
      <div className="m-1 w-25 d-inline-block">ZIP</div>
      <input type="text" name="zip" className="m-1 w-50" value={zip} onChange={handleChange} />
      <div className="m-1 w-25 d-inline-block">Country</div>
      <input
        type="text"
        name="country"
        className="m-1 w-50"
        value={country}
        onChange={handleChange}
      />
      <div className="m-1 w-25 d-inline-block">Version</div>
      <input
        type="text"
        name="version"
        className="m-1 w-50"
        value={version}
        onChange={handleChange}
      />
    </ListGroupItem>
  )
}
