import React, { useContext } from 'react'

import { CasesPageContext } from 'containers/CasesPage/CasesPageContext'
import { Case } from 'domains/case'

export const CaseDate = () => {
  const { casesPageState } = useContext(CasesPageContext)
  const { caseDate } = casesPageState.userCase as Case

  return (
    <tr>
      <td>Case date</td>
      <td>{new Date(caseDate).toISOString().slice(0, 10)}</td>
    </tr>
  )
}
