import React, { useContext, useState } from 'react'
import { Table } from 'reactstrap'

import { API } from 'API'
import { RequestToasterContext } from 'containers/Providers'
import { getUserProfile } from 'core/auth'
import { format } from 'date-fns'
import { FeatureFlags } from 'domains/featureFlags'
import { LanguageRegions } from 'domains/languageRegions'
import { ForbiddenUserRoles, User, UserOperatorRoles, UserRoles } from 'domains/user'
import { StandardErrorResponse } from 'types/APIResponses'
import { isNotNil } from 'utils/isNotNil'

import { PermissionsControl } from './PermissionsControl'

interface UserInfoProps {
  user: User
  getUser: () => void
  featureFlags: FeatureFlags
}

export const UserInfo = ({ user, getUser, featureFlags }: UserInfoProps) => {
  const { username, federatedUser, failedLoginCount, firstLoginAttemptDate, firstName, lastName } =
    user

  const { requestStatusRef } = useContext(RequestToasterContext)

  const [password, setPassword] = useState('')
  const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) =>
    setPassword(event.target.value)

  const [userRole, setUserRole] = useState<UserRoles | ''>(user.userRole ?? '')
  const handleChangeRole = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const userRole = event.target.value as UserRoles
    if (Object.keys(ForbiddenUserRoles).includes(userRole) || userRole == UserRoles.ROLE_NORMAL) {
      setOperatorRole('')
    } else if (userRole == UserRoles.ROLE_OPERATOR) {
      setOperatorRole(UserOperatorRoles.ROLE_SCANNER)
    }
    setUserRole(userRole)
  }

  const [operatorRole, setOperatorRole] = useState<UserOperatorRoles | ''>(
    user.userOperatorRole ?? '',
  )
  const handleChangeOperatorRole = (event: React.ChangeEvent<HTMLSelectElement>) =>
    setOperatorRole(event.target.value as UserOperatorRoles)

  const [languageRegion, setLanguageRegion] = useState<LanguageRegions>(user.languageRegion ?? '')
  const handleChangeSetLanguageRegion = (event: React.ChangeEvent<HTMLSelectElement>) =>
    setLanguageRegion(event.target.value)

  const isUserRoleDisabled = federatedUser || getUserProfile()?.user_name === username
  const isUserOperatorRoleDisabled =
    federatedUser ||
    getUserProfile()?.user_name === username ||
    userRole !== UserRoles.ROLE_OPERATOR
  const isRolesUpdateDisabled =
    federatedUser ||
    getUserProfile()?.user_name === username ||
    (userRole !== null &&
      !Object.values(UserRoles)
        .filter((role) => role !== UserRoles.ROLE_NORMAL)
        .includes(userRole as UserRoles))
  const DSSOFRRole = ['ROLE_DIGITAL_REP', 'ROLE_FIELD_REP']
  const isDSSOFREnabled = featureFlags?.dssOfr ?? false

  const handleUpdateRole = () => {
    API.updateUser({
      username: username,
      userRole: userRole || null,
      userOperatorRole: operatorRole || null,
    })
      .then(() => {
        requestStatusRef.current?.showAlert('Updated', 'success')
        getUser()
      })
      .catch((error: StandardErrorResponse) => {
        requestStatusRef.current?.showAlert(error.response.data.error, 'danger')
      })
  }
  const handleResetFailedLoginCount = () => {
    API.updateUser({ username, failedLoginCount: 0 })
      .then(() => {
        requestStatusRef.current?.showAlert('Updated', 'success')
        getUser()
      })
      .catch((error: StandardErrorResponse) => {
        requestStatusRef.current?.showAlert(error.response.data.error, 'danger')
      })
  }
  const handleUpdateLanguageRegion = () => {
    API.updateLanguageRegion({ username, languageRegion: languageRegion ?? null })
      .then(() => {
        requestStatusRef.current?.showAlert('Updated', 'success')
        getUser()
      })
      .catch((error: StandardErrorResponse) => {
        requestStatusRef.current?.showAlert(error.response.data.error, 'danger')
      })
  }
  const handleUpdatePassword = () => {
    requestStatusRef.current?.startProgress('Resetting user password...', 'secondary')
    API.resetPassword({ username, password })
      .then(() => {
        requestStatusRef.current?.showAlert('Updated', 'success')
        getUser()
      })
      .catch((error: StandardErrorResponse) => {
        requestStatusRef.current?.showAlert(error.response.data.error, 'danger')
      })
  }

  const validateRole = (role: string) => {
    return isDSSOFREnabled ? true : DSSOFRRole.indexOf(role) < 0
  }

  return (
    <>
      <Table bordered className="w-75">
        <tbody>
          <tr>
            <td>User</td>
            <td>
              <span className="mr-1">
                | {firstName} {lastName}
              </span>
              {federatedUser && <span className="mr-1">(Federated)</span>}
            </td>
          </tr>
          {!(featureFlags.asAdminLogInUsingAzureActiveDirectory ?? false) && (
            <tr>
              <td>Role</td>
              <td>
                <form>
                  <select
                    name="userRole"
                    disabled={isUserRoleDisabled}
                    value={userRole}
                    onChange={handleChangeRole}
                    className="w-50 d-block">
                    {Object.values(UserRoles).map(
                      (role, index) =>
                        validateRole(role) && (
                          <option value={role} key={`${role}-${index}`}>
                            {role}
                          </option>
                        ),
                    )}
                  </select>
                  <select
                    name="userOperatorRole"
                    disabled={isUserOperatorRoleDisabled}
                    value={operatorRole}
                    onChange={handleChangeOperatorRole}
                    className="w-50 d-blockk mt-2">
                    {Object.values(UserOperatorRoles).map((role, index) => (
                      <option value={role} key={`${role}-${index}`}>
                        {role}
                      </option>
                    ))}
                    {(userRole === 'ROLE_USER_ADMIN' || userRole === 'ROLE_NORMAL') && (
                      <option value="">null</option>
                    )}
                  </select>
                  <button
                    disabled={isRolesUpdateDisabled}
                    onClick={handleUpdateRole}
                    className="float-right"
                    type="button">
                    Update
                  </button>
                </form>
              </td>
            </tr>
          )}
          <tr>
            <td>Failed logins</td>
            <td>
              <span className="mr-1">Count: {failedLoginCount}</span>
              {isNotNil(firstLoginAttemptDate) && (
                <span className="mr-1">
                  | First attempt: {format(firstLoginAttemptDate, 'dd-MM-yyyy')}
                </span>
              )}
              <button onClick={handleResetFailedLoginCount} className="float-right" type="button">
                Reset
              </button>
            </td>
          </tr>
          <tr>
            <td>Reset Password</td>
            <td>
              <input
                type="password"
                value={password}
                className="w-50 mr-2"
                placeholder="Enter password"
                onChange={handleChangePassword}
              />
              <button
                onClick={handleUpdatePassword}
                className="float-right"
                type="button"
                disabled={federatedUser}>
                Reset
              </button>
            </td>
          </tr>
          <tr>
            <td>Language Region</td>
            <td>
              <form>
                <select
                  name="languageRegion"
                  value={languageRegion}
                  onChange={handleChangeSetLanguageRegion}
                  className="w-50">
                  {Object.values(LanguageRegions).map((region, index) => (
                    <option value={region} key={`${region}-${index}`}>
                      {region}
                    </option>
                  ))}
                  <option value="">null</option>
                </select>
                <button onClick={handleUpdateLanguageRegion} className="float-right">
                  Update
                </button>
              </form>
            </td>
          </tr>
          <tr>
            <td>Feature Permissions</td>

            <td className="w-75">
              <PermissionsControl username={username} />
            </td>
          </tr>
        </tbody>
      </Table>
    </>
  )
}
