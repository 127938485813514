import { EmptyAPIResponse } from 'types/APIResponses'

import { protectedApi } from './requester'

interface ResetPasswordOptions {
  username: string
  password: string
}

export const resetPassword = async ({ username, password }: ResetPasswordOptions) => {
  return protectedApi.put<EmptyAPIResponse>(`/resetPassword/?username=${username}`, { password })
}
