import React from 'react'

import { ScanPayload } from 'containers/ScannersPage/actions'
import { Scan } from 'domains/scan'

interface ScannerItemProps {
  dispatchToState: (value: ScanPayload) => void
  scanner: Scan
}

export const ScannerItem = ({ scanner, dispatchToState }: ScannerItemProps) => {
  const { description, name, uploadScan, version, active, addScanId, directTransfer } = scanner

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    dispatchToState({
      [event.target.name]:
        event.target.type === 'checkbox' ? event.target.checked : event.target.value,
    })

  return (
    <>
      <input
        name="name"
        type="text"
        value={name}
        className="w-auto mr-2"
        size={6}
        placeholder="name"
        onChange={handleChange}
      />
      <input
        name="description"
        type="text"
        value={description}
        className="w-25 mr-2"
        placeholder="description"
        onChange={handleChange}
      />
      <input
        name="version"
        type="text"
        value={version}
        className="w-auto mr-2"
        size={6}
        placeholder="version"
        onChange={handleChange}
      />
      <label htmlFor={`active-new`} className="mr-2">
        active
      </label>
      <input
        name="active"
        id="active-new"
        type="checkbox"
        checked={active}
        className="mr-2"
        onChange={handleChange}
      />
      <label htmlFor={`uploadScan-new`} className="mr-2">
        upload scan
      </label>
      <input
        name="uploadScan"
        id="uploadScan-new"
        type="checkbox"
        checked={uploadScan}
        className="mr-2"
        onChange={handleChange}
      />
      <label htmlFor={`addScanId-new`} className="mr-2">
        Scan ID
      </label>
      <input
        name="addScanId"
        id="addScanId-new"
        type="checkbox"
        checked={addScanId}
        className="mr-2"
        onChange={handleChange}
      />
      <label htmlFor={`directTransfer-new`} className="mr-2">
        Direct Transfer
      </label>
      <input
        name="directTransfer"
        id="directTransfer-new"
        type="checkbox"
        checked={directTransfer}
        className="mr-2"
        onChange={handleChange}
      />
    </>
  )
}
