export const CaseStatusMatrix = {
  ACCEPTED_BY_ANATOMY: 'ACCEPTED_BY_ANATOMY',
  ACCEPTED_BY_DESIGN: 'ACCEPTED_BY_DESIGN',
  ACCEPTED_BY_DESIGN_QC: 'ACCEPTED_BY_DESIGN_QC',
  ACCEPTED_BY_MANUFACTURING: 'ACCEPTED_BY_MANUFACTURING',
  ACCEPTED_BY_SCANNER: 'ACCEPTED_BY_SCANNER',
  ACCEPTED_BY_SHIPPING: 'ACCEPTED_BY_SHIPPING',
  APPROVED_MANUFACTURING: 'APPROVED_MANUFACTURING',
  CHANGES_RETURNED: 'CHANGES_RETURNED',
  COMPLETED_BY_ANATOMY: 'COMPLETED_BY_ANATOMY',
  COMPLETED_BY_DESIGN: 'COMPLETED_BY_DESIGN',
  COMPLETED_BY_MANUFACTURING: 'COMPLETED_BY_MANUFACTURING',
  COMPLETED_BY_SCANNER: 'COMPLETED_BY_SCANNER',
  IMPRESSION_RECEIVED: 'IMPRESSION_RECEIVED',
  NEW: 'NEW',
  PENDING_APPROVAL: 'PENDING_APPROVAL',
  SHIPPED: 'SHIPPED',
  SAVED: 'SAVED',
  PARTIALLY_SHIPPED: 'PARTIALLY_SHIPPED',
} as const

export type CaseStatusMatrix = typeof CaseStatusMatrix[keyof typeof CaseStatusMatrix]
