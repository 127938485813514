import React, { useRef } from 'react'

import { RequestStatus } from 'components/RequestStatus'
import { RequestToasterContext } from 'containers/Providers'

interface RequestToasterProviderProps {
  children: React.ReactChild
}

export const RequestToasterProvider = ({ children }: RequestToasterProviderProps) => {
  const ref = useRef<RequestStatus>(null)

  return (
    <>
      <RequestStatus ref={ref} />
      <RequestToasterContext.Provider value={{ requestStatusRef: ref }}>
        {children}
      </RequestToasterContext.Provider>
    </>
  )
}
