import React, { useContext, useEffect, useState } from 'react'
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom'
import { Container } from 'reactstrap'

import { API } from 'API'
import { AppRoutes } from 'constants/appRoutes'
import { RequestToasterContext } from 'containers/Providers'
import { SystemAccount } from 'domains/account'
import { StandardErrorResponse } from 'types/APIResponses'
import { Nullable } from 'types/utility'
import { isNotNil } from 'utils/isNotNil'

import { AddressesCards, AddressesFilesContent, AddressAccountInfoTable } from './components'

const ADDRESSES_SEARCH_STRING = 'search'

export const AddressesPage = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const searchParams = new URLSearchParams(location.search)
  const { requestStatusRef } = useContext(RequestToasterContext)

  const [search, setSearch] = useState(searchParams.get(ADDRESSES_SEARCH_STRING) ?? '')
  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) =>
    setSearch(event.target.value)

  const [account, setAccount] = useState<Nullable<SystemAccount>>(null)

  useEffect(() => {
    if (search) {
      handleSearch()
    }
  }, [])

  const handlePressSearchKey = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      handleSearch()
    }
  }
  const handleSearch = () => {
    if (!search) {
      setAccount(null)
      navigate(AppRoutes.addresses)
      return
    }
    API.getAccountSearchResults(search)
      .then((response) => {
        setAccount(response)
        navigate({
          pathname: AppRoutes.addresses,
          search: `${createSearchParams({ [ADDRESSES_SEARCH_STRING]: String(search) })}`,
        })
      })
      .catch((error: StandardErrorResponse) => {
        setAccount(null)
        requestStatusRef.current?.showAlert(error.response.data.error, 'danger')
      })
  }

  return (
    <Container>
      <h2>Manage addresses</h2>
      <form>
        <input
          name="search"
          value={search}
          onChange={handleChangeSearch}
          onKeyDown={handlePressSearchKey}
          placeholder="Username or Customer ID or Case ID"
          className="w-75"
        />
        <button onClick={handleSearch} className="m-1" type="button">
          Search
        </button>
      </form>
      {isNotNil(account) && (
        <>
          <AddressAccountInfoTable userAccount={account} />
          <div>
            <AddressesCards account={account} onSearch={handleSearch} />
            <AddressesFilesContent account={account} onSearch={handleSearch} />
          </div>
        </>
      )}
    </Container>
  )
}
