import { Fragment } from 'react'

import {
  FeatureFlagsSchemaEntry,
  FeatureFlagsSchemaObjectEntry,
  FeatureFlagValue,
  GenericFeatureFlags,
} from 'domains/featureFlags'
import { getValueByPath } from 'utils/getValueByPath'

import { FeatureFlagItem } from './FeatureFlagItem'
import { FeatureFlagObject } from './FeatureFlagObject'

interface FeatureFlagRootObjectProps {
  ffSchemaObject: FeatureFlagsSchemaObjectEntry
  dbFFObject: GenericFeatureFlags
}

interface PropertyTempData {
  ffKey: string
  ffData: FeatureFlagsSchemaObjectEntry | FeatureFlagsSchemaEntry
}

export const FeatureFlagRootObject = ({
  ffSchemaObject,
  dbFFObject,
}: FeatureFlagRootObjectProps) => {
  const visibleProperties: PropertyTempData[] = Object.entries(ffSchemaObject.properties)
    .map((entry) => ({
      ffKey: entry[0],
      ffData: entry[1],
    }))
    .filter((permission) => permission.ffData.visible)

  let permissionGroup: PropertyTempData[] = []
  const permissionGroups: PropertyTempData[][] = []

  for (const property of visibleProperties) {
    if (!property.ffData.dependentOn) {
      permissionGroup = []
      permissionGroups.push(permissionGroup)
    }

    permissionGroup.push(property)
  }

  return (
    <>
      {permissionGroups.map((permissionGroup, index) => {
        const showDivider = index > 0

        return (
          <Fragment key={permissionGroup[0].ffKey}>
            {showDivider && <hr />}
            <div className="d-flex flex-column" style={{ gap: '1rem' }}>
              {permissionGroup.map((permission) =>
                permission.ffData.type === 'object' ? (
                  <FeatureFlagObject
                    key={permission.ffKey}
                    path={[permission.ffKey]}
                    ffSchemaObject={permission.ffData}
                    dbFFObject={getValueByPath(dbFFObject, permission.ffKey) as GenericFeatureFlags}
                  />
                ) : (
                  <FeatureFlagItem
                    key={permission.ffKey}
                    path={[permission.ffKey].join('.')}
                    ffSchemaItem={permission.ffData}
                    dbFFItem={
                      getValueByPath(dbFFObject, permission.ffKey) as
                        | FeatureFlagValue
                        | FeatureFlagValue[]
                    }
                  />
                ),
              )}
            </div>
          </Fragment>
        )
      })}
    </>
  )
}
