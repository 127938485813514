import { setToken } from 'core/auth'

import { basicApi } from './requester'

interface UserLoginSSOCredentials {
  code: string
  redirectUri: string
}

export const getSSOAuthenticationFormUrl = (redirectUri: string) =>
  basicApi.get(`/azure/authUrl?redirectUri=${redirectUri}`).then((response) => {
    window.location.replace(response.data)
  })

export const getSSOAuthenticate = ({ code, redirectUri }: UserLoginSSOCredentials) =>
  basicApi.get(`/azure/authentication?code=${code}&redirectUri=${redirectUri}`).then((response) => {
    setToken(response.data)
  })
