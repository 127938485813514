import { Container, Col, Form, FormGroup, Input, Button } from 'reactstrap'

interface LockedAccountProps {
  onCodeChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void
}

export const LockedAccount = ({ onCodeChange, onSubmit }: LockedAccountProps) => {
  return (
    <Container className="w-50 mt-5">
      <h4>Account is locked, enter unlock code</h4>
      <Form className="form" onSubmit={onSubmit}>
        <Col>
          <FormGroup>
            <Input
              type="password"
              name="accountUnlockCode"
              placeholder="Unlock code"
              onChange={onCodeChange}
            />
          </FormGroup>
        </Col>
        <Button type="submit">Submit</Button>
      </Form>
    </Container>
  )
}
