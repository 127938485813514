import React, { useContext, useState } from 'react'

import { API } from 'API'
import { CasesPageContext } from 'containers/CasesPage/CasesPageContext'
import { RequestToasterContext } from 'containers/Providers'
import { Case } from 'domains/case'
import { StandardErrorResponse } from 'types/APIResponses'

export const CaseCancelledInfo = () => {
  const { casesPageState } = useContext(CasesPageContext)
  const { id, cancelled, orderIndicator } = casesPageState.userCase as Case
  const { requestStatusRef } = useContext(RequestToasterContext)

  const [isCancelled, setIsCancelled] = useState(String(cancelled))
  const handleSetIsCancelled = (event: React.ChangeEvent<HTMLSelectElement>) =>
    setIsCancelled(event.target.value)

  const handleUpdateCancelled = () => {
    API.updateCaseById({ id, cancelled: isCancelled === 'true' })
      .then(() => requestStatusRef.current?.showAlert('Updated', 'success'))
      .catch((error: StandardErrorResponse) => {
        requestStatusRef.current?.showAlert(error.response.data.error, 'danger')
      })
  }

  return (
    <tr>
      <td>Cancelled</td>
      <td>
        {isCancelled && (
          <form>
            <select
              name="cancelled"
              disabled={orderIndicator !== 'P'}
              value={isCancelled}
              onChange={handleSetIsCancelled}
              className="w-50">
              <option value="true">yes</option>
              <option value="false">no</option>
            </select>
            <button
              disabled={orderIndicator !== 'P'}
              onClick={handleUpdateCancelled}
              className="float-right"
              type="button">
              Update
            </button>
          </form>
        )}
      </td>
    </tr>
  )
}
