import React from 'react'
import { Alert } from 'reactstrap'

type RequestStatusColor = 'danger' | 'success' | 'secondary'

interface RequestStatusMethods {
  startProgress: (text: string, color: RequestStatusColor) => void
  showAlert: (text: string, color: RequestStatusColor) => void
}

interface RequestStatusState {
  alertText: string
  alertVisible: boolean
  alertColor: string
}

interface RequestStatusProps {
  ref?: React.RefObject<RequestStatusMethods>
}
//TODO: create a custom hook with context instead of this component
class RequestStatus extends React.Component<RequestStatusProps, RequestStatusState> {
  constructor(props: RequestStatusProps) {
    super(props)
    this.state = {
      alertText: '',
      alertVisible: false,
      alertColor: '',
    }
  }

  startProgress = (text: string, color: string) => {
    this.setState({
      alertText: text ? text : 'Loading data',
      alertVisible: true,
      alertColor: color,
    })
  }

  showAlert = (text: string, color: string) => {
    this.setState(
      {
        alertText: text ? text : 'something went wrong',
        alertVisible: true,
        alertColor: color,
      },
      () => {
        window.setTimeout(() => {
          this.setState({ alertVisible: false })
        }, 1000)
      },
    )
  }

  render() {
    return (
      <div className="fixed-top m-5 toast-alert">
        <Alert
          color={this.state.alertColor}
          isOpen={this.state.alertVisible}
          className="w-auto m-5 m-0">
          {this.state.alertText}
        </Alert>
      </div>
    )
  }
}

export default RequestStatus
