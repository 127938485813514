import React, { useContext, useEffect, useRef, useState } from 'react'

import { API } from 'API'
import { photoLabels } from 'constants/photoLables'
import { CasesPageContext } from 'containers/CasesPage/CasesPageContext'
import { RequestToasterContext } from 'containers/Providers'
import { Case } from 'domains/case'
import BlankImg from 'resources/img/blank.png'
import Spinner from 'resources/img/spinner.gif'
import { StandardErrorResponse } from 'types/APIResponses'

export const PhotoItem = ({ item, label }: { item: keyof typeof photoLabels; label: string }) => {
  const { casesPageState, getPhotos } = useContext(CasesPageContext)
  const { photos } = casesPageState
  const { id } = casesPageState.userCase as Case
  const { requestStatusRef } = useContext(RequestToasterContext)
  const fileInputRef = useRef<HTMLInputElement>(null)

  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(false)
  }, [photos])

  const handleClickUpload = () => fileInputRef.current?.click()

  const handlePhotoUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.item(0) ?? null

    if (!file) {
      alert('No file chosen')
      return
    }

    setIsLoading(true)
    API.uploadCasePhoto({
      id,
      item,
      file,
    })
      .then(() => {
        getPhotos()
      })
      .catch((error: StandardErrorResponse) => {
        requestStatusRef.current?.showAlert(error.response.data.error, 'danger')
      })
  }

  const handlePhotoDelete = () => {
    if (!window.confirm('Confirm delete photo "' + item + '"')) {
      return
    }
    setIsLoading(true)
    API.deleteCasePhoto({ id, item })
      .then(() => {
        getPhotos()
      })
      .catch((error: StandardErrorResponse) => {
        requestStatusRef.current?.showAlert(error.response.data.error, 'danger')
      })
  }

  return (
    <div className="card w-25 float-left align-items-center">
      <small className="card-title mr-1 ml-1 mb-0 text-nowrap overflow-hidden">{label}</small>
      <div className="thumbnail-container">
        {isLoading ? (
          <img alt="loading" src={Spinner} className="thumbnail m-1" />
        ) : (
          <img
            alt={`image: ${label}`}
            className="thumbnail m-1"
            src={
              photos?.hasOwnProperty(item) === true
                ? photos[item].urls[photos[item].pathToThumbnail]
                : BlankImg
            }
          />
        )}
      </div>
      <div className="card-footer">
        <input ref={fileInputRef} className="d-none" type="file" onChange={handlePhotoUpload} />
        <button className="mr-1" onClick={handleClickUpload}>
          Upload
        </button>
        <button onClick={handlePhotoDelete}>Delete</button>
      </div>
    </div>
  )
}
