import React, { useState, useEffect, useContext, FC } from 'react'
import { useLocation, useNavigate, createSearchParams } from 'react-router-dom'
import { Container } from 'reactstrap'

import { API } from 'API'
import { RequestToasterContext } from 'containers/Providers'
import { User } from 'domains/user'
import { StandardErrorResponse } from 'types/APIResponses'
import { Nullable } from 'types/utility'

import { UserTable, UserSearchHint } from './components'

const USERS_SEARCH_STRING = 'search'

interface UserSearchProps {
  rootPath: string
  selectedUser: Nullable<string>
  onUserSelected: (username: Nullable<string>) => void
  customAction?: JSX.Element
}

export const UserSearch: FC<UserSearchProps> = ({
  rootPath,
  selectedUser,
  onUserSelected,
  customAction = null,
}) => {
  const navigate = useNavigate()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const { requestStatusRef } = useContext(RequestToasterContext)

  const [query, setQuery] = useState(searchParams.get(USERS_SEARCH_STRING) ?? '')
  const onQueryChanged = (event: React.ChangeEvent<HTMLInputElement>) =>
    setQuery(event.target.value)

  const [matches, setMatches] = useState<User[]>([])

  useEffect(() => {
    if (!selectedUser && query) {
      handleGetMatches()
    }
  }, [])

  useEffect(() => {
    if (selectedUser) {
      setQuery('')
      setMatches([])
    }
  }, [selectedUser])

  const onQueryKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      handleGetMatches()
    }
  }

  const clearMatches = () => {
    onUserSelected(null)
    setMatches([])
  }

  const handleGetMatches = () => {
    if (!query) {
      clearMatches()
      navigate({
        search: '',
      })
      return
    }

    navigate({
      search: `${createSearchParams({ [USERS_SEARCH_STRING]: query })}`,
    })

    requestStatusRef.current?.startProgress('Searching users...', 'secondary')
    API.getUsersList(encodeURIComponent(query))
      .then((response) => {
        if (response.length === 0) {
          clearMatches()
          requestStatusRef.current?.showAlert('Not Found', 'danger')
        } else if (response.length === 1) {
          const { username: responseUsername } = response[0]
          setMatches(response)
          onUserSelected(responseUsername)
          requestStatusRef.current?.showAlert('User found', 'success')
        } else {
          setMatches(response)
          onUserSelected(null)
          requestStatusRef.current?.showAlert('Users found', 'success')
        }
      })
      .catch((error: StandardErrorResponse) => {
        clearMatches()
        requestStatusRef.current?.showAlert(error.response.data.error, 'danger')
      })
  }

  return (
    <Container>
      <form className="w-75 d-flex mb-1">
        <input
          name="search"
          value={query}
          onChange={onQueryChanged}
          onKeyDown={onQueryKeyDown}
          placeholder="search by username, first or last name, or role: admin/operator/doctor"
          className="flex-grow-1"
        />
        <button onClick={handleGetMatches} className="ml-1 mr-1" type="button">
          Search
        </button>
        {customAction}
      </form>
      <UserSearchHint />
      {matches.length !== 0 && !selectedUser && (
        <UserTable rootPath={rootPath} matches={matches} onRedirectToUser={onUserSelected} />
      )}
    </Container>
  )
}
