import { PVSAttachment } from 'domains/pvs'
import { User } from 'domains/user'
import { Nullable } from 'types/utility'

import { PVSReducerActions } from './actions'

export interface PVSState {
  pvsTicketBcc: string
  id: number
  originalCaseId: Nullable<number>
  orderIndicator: Nullable<string>
  doctor: User
  reorder: Nullable<boolean>
  summary: string
  description: string
  attachments: PVSAttachment[]
  doctor_email: string
  emailSubject: string
  emailContent: string
}

export const PVSReducer = (
  state: Nullable<PVSState>,
  action: PVSReducerActions,
): Nullable<PVSState> => {
  switch (action.type) {
    case 'setPVSState':
      return action.payload
    case 'setPVSPageValue':
      return state ? { ...state, ...action.payload } : state
    default:
      return state
  }
}
