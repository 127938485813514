import React, { useContext, useState } from 'react'

import { API } from 'API'
import { CasesPageContext } from 'containers/CasesPage/CasesPageContext'
import { RequestToasterContext } from 'containers/Providers'
import { Case } from 'domains/case'
import { ShipConfirmSource } from 'domains/shipping'
import { StandardErrorResponse } from 'types/APIResponses'

export const CaseShipConfirm = () => {
  const { casesPageState, getCase } = useContext(CasesPageContext)
  const { id } = casesPageState.userCase as Case
  const { requestStatusRef } = useContext(RequestToasterContext)
  const { shippingProviders } = casesPageState
  const defaultShippingProvider = shippingProviders[0]?.name ?? ''

  const [shipConfirmProvider, setShipConfirmProvider] = useState<string>(defaultShippingProvider)
  const handleChangeShipConfirmProvider = (event: React.ChangeEvent<HTMLSelectElement>) =>
    setShipConfirmProvider(event.target.value)

  const [shipConfirmSource, setShipConfirmSource] = useState<ShipConfirmSource>(
    ShipConfirmSource.ebs,
  )
  const handleChangeShipConfirmSource = (event: React.ChangeEvent<HTMLSelectElement>) =>
    setShipConfirmSource(event.target.value as ShipConfirmSource)

  const [shipConfirmTracking, setShipConfirmTracking] = useState('')
  const handleChangeShipConfirmTracking = (event: React.ChangeEvent<HTMLInputElement>) =>
    setShipConfirmTracking(event.target.value)

  const [shipConfirmSalesOrder, setShipConfirmSalesOrder] = useState('')
  const handleChangeShipConfirmSalesOrder = (event: React.ChangeEvent<HTMLInputElement>) =>
    setShipConfirmSalesOrder(event.target.value)

  const [isShipConfirmNoEmail, setIsShipConfirmNoEmail] = useState(true)
  const handleChangeIsShipConfirmNoEmail = (event: React.ChangeEvent<HTMLInputElement>) =>
    setIsShipConfirmNoEmail(event.target.checked)

  const [isShipConfirmNoResponse, setIsShipConfirmNoResponse] = useState(true)
  const handleChangeIsShipConfirmNoResponse = (event: React.ChangeEvent<HTMLInputElement>) =>
    setIsShipConfirmNoResponse(event.target.checked)

  const [isShipConfirmNoMes, setIsShipConfirmNoMes] = useState(false)
  const handleChangeIsShipConfirmNoMes = (event: React.ChangeEvent<HTMLInputElement>) =>
    setIsShipConfirmNoMes(event.target.checked)

  const handleShipConfirm = () => {
    requestStatusRef.current?.startProgress('Ship confirm...', 'secondary')
    API.confirmCaseShip({
      id,
      shippingProvider: shipConfirmProvider,
      trackingNumber: shipConfirmTracking,
      salesOrderNumber: shipConfirmSalesOrder,
      source: shipConfirmSource,
      noEmail: isShipConfirmNoEmail,
      noResponse: isShipConfirmNoResponse,
      noMes: isShipConfirmNoMes,
    })
      .then(() => {
        getCase()
        requestStatusRef.current?.showAlert('Updated', 'success')
      })
      .catch((error: StandardErrorResponse) => {
        requestStatusRef.current?.showAlert(error.response.data.error, 'danger')
      })
  }

  return (
    <tr>
      <td>Ship confirm</td>
      <td>
        <form>
          <select
            name="shipConfirmProvider"
            value={shipConfirmProvider}
            onChange={handleChangeShipConfirmProvider}
            className="w-25">
            {shippingProviders.map((shippingProvider, index) => (
              <option key={`${shippingProvider.name}-${index}`} value={shippingProvider.name}>
                {shippingProvider.displayName}
              </option>
            ))}
          </select>
          <select
            name="shipConfirmSource"
            value={shipConfirmSource}
            onChange={handleChangeShipConfirmSource}
            className="w-25">
            {Object.entries(ShipConfirmSource).map(([key, value], index) => (
              <option key={`${key}-${index}`} value={key}>
                {value}
              </option>
            ))}
          </select>
          <input
            type="text"
            name="shipConfirmTracking"
            placeholder="shipping tracking number"
            value={shipConfirmTracking}
            onChange={handleChangeShipConfirmTracking}
            className="mr-2 w-50"
          />
          <input
            type="text"
            name="shipConfirmSalesOrder"
            placeholder="sales order number"
            value={shipConfirmSalesOrder}
            onChange={handleChangeShipConfirmSalesOrder}
            className="mr-2 w-25"
          />
          <br />
          <input
            checked={isShipConfirmNoEmail}
            id="isShipConfirmNoEmail"
            type="checkbox"
            onChange={handleChangeIsShipConfirmNoEmail}
          />
          <label className="m-1 mr-3" htmlFor="shipConfirmNoEmail">
            Do not send email
          </label>
          <input
            checked={isShipConfirmNoResponse}
            id="shipConfirmNoResponse"
            type="checkbox"
            onChange={handleChangeIsShipConfirmNoResponse}
          />
          <label className="m-1 mr-3" htmlFor="shipConfirmNoResponse">
            Do not respond to ERP
          </label>
          <input
            checked={isShipConfirmNoMes}
            id="shipConfirmNoMes"
            type="checkbox"
            onChange={handleChangeIsShipConfirmNoMes}
          />
          <label className="m-1 mr-3" htmlFor="shipConfirmNoMes">
            Do not notify MES
          </label>
          <button onClick={handleShipConfirm} className="float-right" type="button">
            Confirm shipment
          </button>
        </form>
      </td>
    </tr>
  )
}
