import { ProductInfo } from 'domains/productInfo'

export const filterProducts = (
  selectedProductId: ProductInfo[keyof ProductInfo],
  productId: ProductInfo[keyof ProductInfo],
) => {
  if (
    selectedProductId == ProductInfo['Spark Passive Aligners'] ||
    selectedProductId == ProductInfo.Retainers ||
    selectedProductId == ProductInfo['Spark Junior Phase 2'] ||
    selectedProductId == ProductInfo['Spark Retainers']
  ) {
    return true
  }

  return (
    productId !== ProductInfo['Spark Passive Aligners'] &&
    productId !== ProductInfo.Retainers &&
    productId !== ProductInfo['Spark Junior Phase 2'] &&
    productId !== ProductInfo['Spark Retainers']
  )
}
