import React from 'react'

import { PVSAttachment } from 'domains/pvs'
import { convertFileSizeToHumanReadable } from 'utils/convertFileSizeToHumanReadable'

interface PvsAttachmentProps {
  attachment: PVSAttachment
  removeAttachment: (id: number) => void
}

export const PVSAttachmentItem = ({ attachment, removeAttachment }: PvsAttachmentProps) => {
  const handleRemoveAttachment = () => removeAttachment(attachment.id)

  return (
    <li className="mb-3">
      <a target="_blank" href={attachment.url}>
        {attachment.filename} | {convertFileSizeToHumanReadable(attachment.size)}
      </a>
      <button className="float-right" onClick={handleRemoveAttachment}>
        remove
      </button>
    </li>
  )
}
