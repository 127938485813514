import React from 'react'

import { Footer } from 'components/Footer'
import { Header } from 'components/Header'

import { AppRouter } from '../AppRouter'

export const Layout = () => {
  return (
    <>
      <Header />
      <main className="layout__main">
        <AppRouter />
      </main>
      <Footer />
    </>
  )
}
