import { FC } from 'react'
import { Table } from 'reactstrap'

import { User } from 'domains/user'

import { MatchItem } from './MatchItem'

interface UserTableProps {
  rootPath: string
  matches: User[]
  onRedirectToUser: (username: string) => void
}

export const UserTable: FC<UserTableProps> = ({ rootPath, matches, onRedirectToUser }) => {
  return (
    <Table bordered className="w-75">
      <thead>
        <tr>
          <th>username</th>
          <th>role</th>
          <th>name</th>
        </tr>
      </thead>
      <tbody>
        {matches.map((item, index) => (
          <MatchItem
            rootPath={rootPath}
            key={index}
            user={item}
            onRedirectToUser={onRedirectToUser}
          />
        ))}
      </tbody>
    </Table>
  )
}
