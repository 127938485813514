import axios from 'axios'

import { getBaseUrl, getProtectedHeaders, getFileHeaders } from './middleware'

export const fileApi = axios.create()

fileApi.interceptors.request.use(
  async (config) => getBaseUrl(config).then(getFileHeaders).then(getProtectedHeaders),
  (error) => Promise.reject(error),
)
