import React, { useContext } from 'react'

import { API } from 'API'
import { photoLabels } from 'constants/photoLables'
import { CasesPageContext } from 'containers/CasesPage/CasesPageContext'
import { RequestToasterContext } from 'containers/Providers'
import { Case } from 'domains/case'
import { StandardErrorResponse } from 'types/APIResponses'
import { isNotNil } from 'utils/isNotNil'

import { PhotoItem } from '../PhotoItem'

export const CasePhotos = () => {
  const { casesPageState, getPhotos } = useContext(CasesPageContext)
  const { photos } = casesPageState
  const { id } = casesPageState.userCase as Case
  const { requestStatusRef } = useContext(RequestToasterContext)

  const handleFixPhotos = () => {
    requestStatusRef.current?.startProgress('Fixing photos...', 'secondary')
    API.fixCasePhotos(id)
      .then(() => {
        getPhotos()
        requestStatusRef.current?.showAlert('Updated', 'success')
      })
      .catch((error: StandardErrorResponse) => {
        requestStatusRef.current?.showAlert(error.response.data.error, 'danger')
      })
  }

  return (
    <tr>
      <td>Photos</td>
      <td>
        {isNotNil(
          (Object.keys(photoLabels) as Array<keyof typeof photoLabels>).find(
            (item) => photos?.hasOwnProperty(item) === true && photos[item].formatName !== 'JPEG',
          ),
        ) && (
          <div className="mb-2 p-3 border alert-warning">
            <span>WARNING: some images are not in JPEG format</span>
            <button className="float-right" onClick={handleFixPhotos}>
              Fix this
            </button>
          </div>
        )}
        <div className="row match-height">
          {(
            Object.entries(photoLabels) as Array<[key: keyof typeof photoLabels, value: string]>
          ).map(([item, label], index) => (
            <PhotoItem item={item} label={label} key={`${item}-${index}`} />
          ))}
        </div>
      </td>
    </tr>
  )
}
