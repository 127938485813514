import React, { useLayoutEffect, useState } from 'react'
import { Router } from 'react-router-dom'

import { BrowserHistory } from 'history'

interface HistoryProviderProps {
  history: BrowserHistory
  children: React.ReactChild
}

export const HistoryProvider = ({ children, history }: HistoryProviderProps) => {
  const [state, setState] = useState({
    action: history.action,
    location: history.location,
  })

  useLayoutEffect(() => history.listen(setState), [history])

  return (
    <Router
      children={children}
      location={state.location}
      navigationType={state.action}
      navigator={history}
    />
  )
}
