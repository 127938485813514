import { ChangeEvent } from 'react'
import { FormGroup, Input, Label } from 'reactstrap'

interface FeatureFlagInputItemProps {
  title: string
  inputType: 'text' | 'number'
  value: string | number
  onChange: (value: string | number) => void
  disabled?: boolean
}

export const FeatureFlagInputItem = ({
  title,
  inputType,
  value,
  onChange,
  disabled,
}: FeatureFlagInputItemProps) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => onChange(event.target.value)

  return (
    <FormGroup>
      <Label>{title}</Label>
      <Input type={inputType} disabled={disabled} value={value} onChange={handleChange} />
    </FormGroup>
  )
}
