import { Support } from 'domains/support'

import { SetSupportItemInputAction } from './actions'

export const supportItemReducer = (state: Support, action: SetSupportItemInputAction) => {
  if (action.type === 'setSupportInputValue') {
    return {
      ...state,
      ...action.payload,
    }
  }

  return state
}
