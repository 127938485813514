import { useState, useEffect } from 'react'
import { Button } from 'reactstrap'

import { API } from 'API'
import { errorMap } from 'containers/LoginPage/constants'
import { ActiveDirectoryURLs } from 'domains/auth'

import { LoginSSOError } from '..'

interface LoginSSOProps {
  error: string
  onChangeLoginType: (event: React.MouseEvent) => void
  onSubmit: () => void
}

export const LoginSSO = ({ error, onChangeLoginType, onSubmit }: LoginSSOProps) => {
  const [activeDirectoryURLs, setActiveDirectoryURLs] = useState<ActiveDirectoryURLs>({
    ticket: '',
    PDFGuide: '',
  })

  useEffect(() => {
    if (error) {
      API.getSupportURLs().then((response) => {
        setActiveDirectoryURLs({
          ticket: response.activeDirectoryTicketUrl,
          PDFGuide: response.activeDirectoryPdfGuideUrl,
        })
      })
    }
  }, [error])

  return (
    <>
      <Button className="w-100 mt-5 mb-3" outline onClick={onSubmit}>
        LOGIN
      </Button>
      <p>
        If your account is authorized to login with DTX credentials,&nbsp;
        <a
          href="#"
          className="font-weight-bold text-decoration-none text-dark"
          onClick={onChangeLoginType}>
          click here
        </a>
      </p>

      {error === errorMap.noFederatedUser && (
        <LoginSSOError
          error="Your Envista account is not properly set. Please, go to Spark DTX portal to login and
            use your Envista account. Once you have successfully logged in, please return to this
            portal to try again."
          condition="If you have already logged in DTX and still face issues with logging in"
          activeDirectoryURLs={activeDirectoryURLs}
        />
      )}

      {error === errorMap.noAdminRole && (
        <LoginSSOError
          error="You are not authorized to login in with the role that is currently assigned to your
        Envista account. Please, contact your system administrator to set your Envista account
        and try again later."
          condition="In case you are still facing issues with logging in"
          activeDirectoryURLs={activeDirectoryURLs}
        />
      )}
    </>
  )
}
