import React, { useContext, useState } from 'react'

import { API } from 'API'
import { CasesPageContext } from 'containers/CasesPage/CasesPageContext'
import { RequestToasterContext } from 'containers/Providers'
import { Case } from 'domains/case'
import { StandardErrorResponse } from 'types/APIResponses'

export const CaseShipTracking = () => {
  const { casesPageState, getCase } = useContext(CasesPageContext)
  const {
    shippingTrackingNumber: initialShippingTrackingNumber,
    shippingProvider: initialShippingProvider,
    id,
  } = casesPageState.userCase as Case
  const { requestStatusRef } = useContext(RequestToasterContext)
  const { shippingProviders } = casesPageState
  const defaultShippingProvider = shippingProviders[0]?.name ?? ''

  const [shippingProvider, setShippingProvider] = useState(
    initialShippingProvider ?? defaultShippingProvider,
  )
  const handleChangeShippingProvider = (event: React.ChangeEvent<HTMLSelectElement>) =>
    setShippingProvider(event.target.value)

  const [shippingTrackingNumber, setShippingTrackingNumber] = useState(
    initialShippingTrackingNumber ?? '',
  )
  const handleChangeTrackingNumber = (event: React.ChangeEvent<HTMLInputElement>) =>
    setShippingTrackingNumber(event.target.value)

  const handleUpdateShippingInfo = () => {
    API.updateCaseById({
      id,
      shippingProvider,
      shippingTrackingNumber,
    })
      .then(() => {
        getCase()
        requestStatusRef.current?.showAlert('Updated', 'success')
      })
      .catch((error: StandardErrorResponse) => {
        requestStatusRef.current?.showAlert(error.response.data.error, 'danger')
      })
  }

  return (
    <tr>
      <td>Tracking</td>
      <td>
        <form>
          <select
            name="shippingProvider"
            value={shippingProvider}
            onChange={handleChangeShippingProvider}
            className="mr-2 w-25">
            {shippingProviders.map((provider, index) => (
              <option key={`${provider.name}-${index}`} value={provider.name}>
                {provider.displayName}
              </option>
            ))}
          </select>
          <input
            type="text"
            name="shippingTrackingNumber"
            placeholder="shipping tracking number"
            value={shippingTrackingNumber}
            onChange={handleChangeTrackingNumber}
            className="mr-2 w-50"
          />
          <button onClick={handleUpdateShippingInfo} className="float-right" type="button">
            Update
          </button>
        </form>
      </td>
    </tr>
  )
}
