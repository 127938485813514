import React, { useEffect, useState } from 'react'
import { Col, Form, FormGroup, Input, Button } from 'reactstrap'

import { API } from 'API'
import { ActiveDirectoryURLs } from 'domains/auth'

interface LoginFormProps {
  isBackButtonVisible: boolean
  onChangeLoginType: (event: React.MouseEvent) => void
  onUserNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onPasswordChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void
}

export const LoginForm = ({
  isBackButtonVisible,
  onChangeLoginType,
  onUserNameChange,
  onPasswordChange,
  onSubmit,
}: LoginFormProps) => {
  const [activeDirectoryURLs, setActiveDirectoryURLs] = useState<ActiveDirectoryURLs>({
    ticket: '',
    PDFGuide: '',
  })

  useEffect(() => {
    API.getSupportURLs().then((response) => {
      setActiveDirectoryURLs({
        ticket: response.activeDirectoryTicketUrl,
        PDFGuide: response.activeDirectoryPdfGuideUrl,
      })
    })
  }, [])

  return (
    <Form className="form" onSubmit={onSubmit}>
      <Col>
        <FormGroup>
          <Input type="text" name="username" placeholder="Username" onChange={onUserNameChange} />
        </FormGroup>
      </Col>
      <Col>
        <FormGroup>
          <Input
            type="password"
            name="password"
            placeholder="Password"
            onChange={onPasswordChange}
          />
        </FormGroup>
        <p>
          If you have issues with logging in, you can create a&nbsp;
          <a
            href={activeDirectoryURLs.ticket}
            className="font-weight-bold text-decoration-none text-dark">
            Service Ticket&nbsp;
          </a>
          or look into the&nbsp;
          <a
            href={activeDirectoryURLs.PDFGuide}
            className="font-weight-bold text-decoration-none text-dark">
            User Guide
          </a>
        </p>
      </Col>

      <Col className="d-flex justify-content-between">
        <Button type="submit">Submit</Button>

        {isBackButtonVisible && (
          <Button type="button" outline onClick={onChangeLoginType}>
            Back to Login
          </Button>
        )}
      </Col>
    </Form>
  )
}
