import {
  FeatureFlags,
  FeatureFlagsContext,
  GenericFeatureFlags,
  FeatureFlagsWithSchema,
} from 'domains/featureFlags'
import jsonBig from 'json-bigint'

import { basicApi, protectedApi } from './requester'

export const getFeatureFlags = async () => {
  return basicApi.get<FeatureFlags>('/features').then((response) => response.data)
}

export const getUsersFeatureFlags = (username: string, application: string) => {
  return protectedApi
    .get<GenericFeatureFlags>(`/features/`, {
      params: {
        username,
        application,
      },
    })
    .then((response) => response.data)
}

export const getUsersFeatureFlagsWithSchema = (username: string) => {
  return protectedApi
    .get<FeatureFlagsWithSchema>(`/user/${username}/features/`, {
      transformResponse: (response) => jsonBig({ useNativeBigInt: true }).parse(response),
    })
    .then((response) => response.data)
}

export const updateFeatureFlagsOverride = (
  username: string,
  featureFlagsOverride: Record<string, unknown>,
) => {
  return protectedApi
    .put<FeatureFlagsWithSchema>(`/user/${username}/features/override`, featureFlagsOverride, {
      transformRequest: (request) => jsonBig({ useNativeBigInt: true }).stringify(request),
      transformResponse: (response) => jsonBig({ useNativeBigInt: true }).parse(response),
    })
    .then((response) => response.data)
}

export const getUsersFeatureFlagsContext = (user: string, application: string) => {
  return protectedApi
    .get<FeatureFlagsContext>(`/user/${user}/features/context`, {
      params: {
        application,
      },
    })
    .then((response) => response.data)
}
