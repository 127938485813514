import React, { useContext, useState } from 'react'

import { API } from 'API'
import { RequestToasterContext } from 'containers/Providers/RequestToasterProvider'
import { StandardErrorResponse } from 'types/APIResponses'

interface AccountSalesRepProps {
  username: string
  initSalesRep: string
}

export const AccountSalesRep = ({ username, initSalesRep }: AccountSalesRepProps) => {
  const [salesRep, setSalesRep] = useState(initSalesRep)
  const handleChangeSalesRep = (event: React.ChangeEvent<HTMLInputElement>) =>
    setSalesRep(event.target.value)

  const { requestStatusRef } = useContext(RequestToasterContext)

  const handleUpdateSalesRep = () => {
    API.updateAccountParams({ username, salesRep })
      .then(() => requestStatusRef.current?.showAlert('Updated', 'success'))
      .catch((error: StandardErrorResponse) => {
        requestStatusRef.current?.showAlert(error.response.data.error, 'danger')
      })
  }

  return (
    <tr>
      <td>Sales Rep userid</td>
      <td>
        <form>
          <input
            name="salesRep"
            onChange={handleChangeSalesRep}
            value={salesRep}
            placeholder="Sales Rep"
            className="w-75"
          />
          <button onClick={handleUpdateSalesRep} className="float-right" type="button">
            Update
          </button>
        </form>
      </td>
    </tr>
  )
}
