import React, { useContext, useState } from 'react'

import { API } from 'API'
import { CasesPageContext } from 'containers/CasesPage/CasesPageContext'
import { RequestToasterContext } from 'containers/Providers'
import { Case } from 'domains/case'

export const CaseDoctor = () => {
  const { casesPageState, getCase } = useContext(CasesPageContext)
  const { id, doctor } = casesPageState.userCase as Case
  const { requestStatusRef } = useContext(RequestToasterContext)

  const [doctorUsername, setDoctorUsername] = useState(doctor.username)
  const handleChangeDoctorUsername = (event: React.ChangeEvent<HTMLInputElement>) =>
    setDoctorUsername(event.target.value)

  const handleTransferPatient = () => {
    API.transferCases({ toUsername: doctorUsername, publicCaseIds: [id] }).then(
      (errorMessage?: string) => {
        if (errorMessage !== undefined && errorMessage !== '') {
          return requestStatusRef.current?.showAlert(errorMessage, 'danger')
        }

        getCase()
        requestStatusRef.current?.showAlert('Updated', 'success')
      },
    )
  }

  return (
    <tr>
      <td>Doctor</td>
      <td>
        <form>
          <input
            type="text"
            value={doctorUsername}
            className="mr-2 w-25"
            onChange={handleChangeDoctorUsername}
          />
          {doctor.firstName} {doctor.lastName}
          <button onClick={handleTransferPatient} className="float-right" type="button">
            Transfer
          </button>
        </form>
      </td>
    </tr>
  )
}
