import { Button, FormGroup, Input, Label } from 'reactstrap'

export type FFListItemType = string | number

interface FeatureFlagArrayItemProps {
  title: string
  value: FFListItemType[]
  lockedValues?: FFListItemType[]
  onChange: (values: FFListItemType[]) => void
  options: FFListItemType[]
  displayValues?: Record<string, string>
  disabled?: boolean
}

export const FeatureFlagArrayItem = ({
  title,
  value = [],
  lockedValues = [],
  disabled,
  options,
  displayValues,
  onChange,
}: FeatureFlagArrayItemProps) => {
  const handleChange = (actualValue: FFListItemType) => {
    const newValue = value.find((item) => item.toString() === actualValue.toString())
      ? value.filter((item) => item.toString() !== actualValue.toString())
      : [...value, actualValue]

    onChange(newValue)
  }

  const toggleAll = () => {
    if (value.length === options.length) {
      onChange(lockedValues)
    } else {
      onChange(options)
    }
  }

  const sortedOptions = options
    .map((optionKey) => ({
      key: optionKey,
      value: displayValues?.[optionKey] ?? optionKey.toString(),
    }))
    .sort((a, b) => (a.value > b.value ? 1 : -1))

  return (
    <>
      <div className="d-flex justify-content-between">
        <Label>{title}</Label>
        <Button color="link" size="sm" onClick={toggleAll}>
          Select all
        </Button>
      </div>
      <div
        className="p-2 d-flex flex-column"
        style={{
          border: 'solid #dee2e6 1px',
          borderRadius: 4,
          maxHeight: 180,
          overflow: 'auto',
          gap: '0.5rem',
        }}>
        {sortedOptions.map((option) => (
          <FormGroup key={option.key} check>
            <Label className="mb-0">
              <Input
                type="checkbox"
                disabled={disabled || lockedValues.includes(option.key)}
                checked={Boolean(value.find((item) => item.toString() === option.key.toString()))}
                onChange={() => handleChange(option.key)}
              />
              {option.value}
            </Label>
          </FormGroup>
        ))}
      </div>
    </>
  )
}
