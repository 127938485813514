import React, { useContext } from 'react'

import { CasesPageContext } from 'containers/CasesPage/CasesPageContext'
import { Case, CaseStatusMatrix } from 'domains/case'

interface AddressSelectProps {
  selectName: string
  addressId: number
  changeAddressId: (event: React.ChangeEvent<HTMLSelectElement>) => void
}

export const AddressSelect = (props: AddressSelectProps) => {
  const { selectName, addressId, changeAddressId } = props
  const { casesPageState } = useContext(CasesPageContext)
  const { shipAddressData } = casesPageState
  const { doctor, caseStatus, cancelled } = casesPageState.userCase as Case
  const isSectionDisabled = caseStatus === CaseStatusMatrix.SHIPPED || cancelled

  return (
    <select
      name={selectName}
      disabled={isSectionDisabled}
      value={addressId}
      onChange={changeAddressId}
      className="w-50">
      {shipAddressData.map((item) => {
        const { address1, city, state, postalCode, country, accountId } = item
        const isAddressBelongToDoctorPractice = doctor?.accountId === accountId

        return (
          <option key={item.id} disabled={!isAddressBelongToDoctorPractice} value={item.id}>
            {`${address1 ?? ''}, ${city ?? ''}, ${state ?? ''}, ${postalCode ?? ''}, ${
              country ?? ''
            } ${!isAddressBelongToDoctorPractice ? 'This address belongs to another doctor' : ''}`}
          </option>
        )
      })}
    </select>
  )
}
