import { FC } from 'react'
import { Table } from 'reactstrap'

import { GenericFeatureFlags } from 'domains/featureFlags'

import { PermissionItem } from './PermissionItem'

interface PermissionsTableProps {
  searchQuery?: string
  flagsData: GenericFeatureFlags
}

export const PermissionsTable: FC<PermissionsTableProps> = ({ searchQuery, flagsData }) => {
  return (
    <Table bordered className="w-75">
      <thead>
        <tr>
          <th>flag</th>
          <th>value</th>
        </tr>
      </thead>
      <tbody>
        <PermissionItem flagsData={flagsData} searchQuery={searchQuery} />
      </tbody>
    </Table>
  )
}
