import { ITeroDoctor } from 'domains/itero'

interface ITeroDoctorItemProps {
  doctor: ITeroDoctor
  onDelete: () => void
}

export const ITeroDoctorItem = ({ doctor, onDelete }: ITeroDoctorItemProps) => (
  <tr>
    <td className="small">{doctor.username}</td>
    <td className="small">{doctor.doctor}</td>
    <td className="small">{doctor.iteroDoctor}</td>
    <td className="small">
      {new Date(doctor.createdDate).toISOString().slice(0, 19).replace('T', ' ')}
    </td>
    <td className="small">
      <button onClick={onDelete}>Delete</button>
    </td>
  </tr>
)
