import React, { useContext, useEffect, useState } from 'react'

import { API } from 'API'
import { CasesPageContext } from 'containers/CasesPage/CasesPageContext'
import { RequestToasterContext } from 'containers/Providers'
import { Case } from 'domains/case'
import { TreatArches } from 'domains/treatment'
import { StandardErrorResponse } from 'types/APIResponses'

export const CaseTreatArches = () => {
  const { casesPageState, getCase } = useContext(CasesPageContext)
  const { id } = casesPageState.userCase as Case
  const { treatArches: initialTreatArches } = casesPageState.treatment
  const { requestStatusRef } = useContext(RequestToasterContext)

  const [treatArches, setTreatArches] = useState('')
  const handleChangeTreatArches = (event: React.ChangeEvent<HTMLSelectElement>) =>
    setTreatArches(event.target.value as TreatArches)

  useEffect(() => {
    setTreatArches(initialTreatArches ?? '')
  }, [initialTreatArches])

  const handleUpdateTreatArches = () => {
    if (treatArches == null) {
      return
    }

    API.updateCaseById({ id, treatArches })
      .then(() => {
        getCase()
        requestStatusRef.current?.showAlert('Updated', 'success')
      })
      .catch((error: StandardErrorResponse) => {
        requestStatusRef.current?.showAlert(error.response.data.error, 'danger')
      })
  }

  return (
    <tr>
      <td>Treat arches</td>
      <td>
        <select value={treatArches} onChange={handleChangeTreatArches} className="w-50">
          <option value=""> </option>
          {Object.entries(TreatArches).map(([key, value], index) => (
            <option key={`${key}-${index}`} value={key}>
              {value}
            </option>
          ))}
        </select>
        <button onClick={handleUpdateTreatArches} className="float-right" type="button">
          Update
        </button>
      </td>
    </tr>
  )
}
