import React, { useContext, useRef, useState } from 'react'

import { API } from 'API'
import { RequestToasterContext } from 'containers/Providers/RequestToasterProvider'
import { StandardErrorResponse } from 'types/APIResponses'

export const AccountImportOFR = () => {
  const [importOFRStatus, setImportOFRStatus] = useState('')
  const [isImportOFRApply, setIsImportOFRApply] = useState(false)
  const handleToggleIsImportOFRApply = (event: React.ChangeEvent<HTMLInputElement>) =>
    setIsImportOFRApply(event.target.checked)

  const { requestStatusRef } = useContext(RequestToasterContext)
  const fileInputRef = useRef<HTMLInputElement>(null)

  const handleImportOFR = () => {
    const file = fileInputRef.current?.files?.item(0) ?? null

    if (!file) {
      alert('No file chosen')
      return
    }

    requestStatusRef.current?.startProgress('Importing file...', 'secondary')
    API.importAccountOFR({
      importOFRApply: isImportOFRApply,
      file,
    })
      .then((result) => {
        requestStatusRef.current?.showAlert('File imported', 'success')
        setImportOFRStatus(result)
      })
      .catch((error: StandardErrorResponse) => {
        requestStatusRef.current?.showAlert(error.response.data.error, 'danger')
      })
  }

  return (
    <div className="mt-1">
      <span className="w-25 d-inline-block">
        Import OFR{' '}
        <a className="m-1" href="/resources/OFR-assingement-sample.xlsx">
          sample file
        </a>
      </span>
      <input className="" ref={fileInputRef} type="file" />
      <input type="checkbox" id="apply" className="mr-1" onChange={handleToggleIsImportOFRApply} />
      <label className="mr-1" htmlFor="apply">
        Apply
      </label>
      <button className="mr-1" onClick={handleImportOFR}>
        Import
      </button>
      <pre className="mt-3">{importOFRStatus}</pre>
    </div>
  )
}
