import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Container } from 'reactstrap'

import { RequestStatus } from 'components/RequestStatus'
import { UserSearch } from 'components/UserSearch'
import { AppRoutes } from 'constants/appRoutes'
import { Nullable } from 'types/utility'
import { isNotNil } from 'utils/isNotNil'

import { PermissionsInfo } from './components'

const USER_PARAMS = { userId: 'userId' } as const

export const PermissionsPage = () => {
  const navigate = useNavigate()
  const params = useParams()

  const [username, setUsername] = useState(params[USER_PARAMS.userId] ?? null)

  const onUsernameChange = (newValue: Nullable<string>) => {
    setUsername(newValue)

    if (!newValue) {
      return
    }

    navigate(`${AppRoutes.permissions}/${newValue}`)
  }

  return (
    <Container>
      <RequestStatus />
      <h2>View permissions</h2>
      <UserSearch
        rootPath={AppRoutes.permissions}
        selectedUser={username}
        onUserSelected={onUsernameChange}
      />
      {isNotNil(username) && <PermissionsInfo username={username} />}
    </Container>
  )
}
