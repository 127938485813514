import React, { useContext, useState } from 'react'

import { API } from 'API'
import { RequestToasterContext } from 'containers/Providers/RequestToasterProvider'
import { StandardErrorResponse } from 'types/APIResponses'

interface AccountCasePointsAdjustmentProps {
  username: string
  initAdjustment: string
}

export const AccountCasePointsAdjustment = ({
  username,
  initAdjustment,
}: AccountCasePointsAdjustmentProps) => {
  const [adjustment, setAdjustment] = useState(initAdjustment)
  const handleChangeAdjustments = (event: React.ChangeEvent<HTMLInputElement>) =>
    setAdjustment(event.target.value)

  const { requestStatusRef } = useContext(RequestToasterContext)

  const handleUpdateCasePoints = () => {
    API.updateAccountParams({
      username,
      adjustment,
    })
      .then(() => requestStatusRef.current?.showAlert('Updated', 'success'))
      .catch((error: StandardErrorResponse) => {
        requestStatusRef.current?.showAlert(error.response.data.error, 'danger')
      })
  }

  return (
    <tr>
      <td>Case points adjustment</td>
      <td>
        <form>
          <input
            type="number"
            pattern="[0-9]"
            name="adjustment"
            onChange={handleChangeAdjustments}
            value={adjustment}
            placeholder="Adjustment"
            className="w-75"
          />
          <button
            onClick={handleUpdateCasePoints}
            className="float-right"
            type="button"
            disabled={adjustment === ''}>
            Update
          </button>
        </form>
      </td>
    </tr>
  )
}
