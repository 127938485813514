import React, { useContext, useRef } from 'react'

import { API } from 'API'
import { CasesPageContext } from 'containers/CasesPage/CasesPageContext'
import { RequestToasterContext } from 'containers/Providers'
import { StandardErrorResponse } from 'types/APIResponses'

export const CaseOfficePrinting = () => {
  const { casesPageState } = useContext(CasesPageContext)
  const { printCaseIdData } = casesPageState
  const { requestStatusRef } = useContext(RequestToasterContext)
  const fileInputRef = useRef<HTMLInputElement>(null)
  const casePrintIdInputRef = useRef<HTMLSelectElement>(null)

  const handlePrintResponse = () => {
    if (!casePrintIdInputRef.current) {
      alert('No case id chosen')
      return
    }

    if (!fileInputRef.current?.files?.item(0)) {
      alert('No file chosen')
      return
    }

    requestStatusRef.current?.startProgress('Uploading file...', 'secondary')

    API.printCaseResponse({
      casePrintId: Number(casePrintIdInputRef.current.value),
      file: fileInputRef.current.files.item(0) as File,
    })
      .then(() => {
        requestStatusRef.current?.showAlert('File uploaded', 'success')
      })
      .catch((error: StandardErrorResponse) => {
        requestStatusRef.current?.showAlert(error.response.data.error, 'danger')
      })
  }

  return (
    <tr>
      <td>In Office Printing</td>
      <td>
        {<span className="casId_text">Case Id :</span>}
        <select ref={casePrintIdInputRef} name="casePrintId" className="caseId_selector">
          {printCaseIdData.map(({ id, orderIndicator }, index) => (
            <option key={index} value={id}>
              {orderIndicator}
            </option>
          ))}
        </select>
        <input ref={fileInputRef} className="in_office_print_upload_button" type="file" />
        <button className="float-right" onClick={handlePrintResponse}>
          Upload
        </button>
        <div className="w-60 border alert-warning in_office_print_note_text">
          <span>Note: Please upload a file in .zip format</span>
        </div>
      </td>
    </tr>
  )
}
