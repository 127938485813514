import { Link } from 'react-router-dom'

import { operatorRolesMap, rolesMap } from 'constants/userRoles'
import { User } from 'domains/user'

interface MatchItemProps {
  rootPath: string
  user: User
  onRedirectToUser: (username: string) => void
}

export const MatchItem = ({ rootPath, user, onRedirectToUser }: MatchItemProps) => {
  const { username, userOperatorRole, userRole, firstName, lastName } = user

  const handleChangeUsername = () => onRedirectToUser(user.username)

  return (
    <tr>
      <td>
        <Link onClick={handleChangeUsername} to={`${rootPath}/${encodeURIComponent(username)}`}>
          {username}
        </Link>
      </td>
      <td>{(userOperatorRole && operatorRolesMap[userOperatorRole]) ?? rolesMap[userRole]}</td>
      <td>
        {firstName} {lastName}
      </td>
    </tr>
  )
}
