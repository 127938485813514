import React, { useContext, useEffect, useState } from 'react'

import { API } from 'API'
import { RequestToasterContext } from 'containers/Providers'
import { StandardErrorResponse } from 'types/APIResponses'

const WebContentPage = () => {
  const { requestStatusRef } = useContext(RequestToasterContext)

  const [content, setContent] = useState<string | undefined>('')
  const handleChangeContent = (event: React.ChangeEvent<HTMLTextAreaElement>) =>
    setContent(event.target.value)

  const [showMsg, setShowMsg] = useState(false)
  const handleChangeShowMsg = (event: React.ChangeEvent<HTMLInputElement>) =>
    setShowMsg(event.target.checked)

  const getWebContent = async () => {
    API.getWebContent()
      .then((response) => {
        setContent(JSON.stringify(response, undefined, 4))
      })
      .catch((error: StandardErrorResponse) => {
        requestStatusRef.current?.showAlert(error.response.data.error, 'danger')
      })
  }
  const updateWebContent = async () => {
    requestStatusRef.current?.startProgress('Updating...', 'secondary')
    API.updateWebContent({ showMsg, content })
      .then(() => {
        requestStatusRef.current?.showAlert('Updated', 'success')
        getWebContent()
      })
      .catch((error: StandardErrorResponse) => {
        requestStatusRef.current?.showAlert(error.response.data.error, 'danger')
      })
  }

  useEffect(() => {
    getWebContent()
  }, [])

  return (
    <div className="page-container container">
      <h2>WebContent</h2>
      <div>
        <input
          id={`show-mkt-msg`}
          type="checkbox"
          checked={showMsg}
          className="mr-1"
          onChange={handleChangeShowMsg}
        />
        <label htmlFor={`show-mkt-msg`}>Show marketting message</label>
        <button onClick={updateWebContent} className="webcontent__update_btn" type="button">
          Update
        </button>
      </div>
      <div>
        <div key="new">
          <textarea
            value={content}
            className="webcontent__textbox"
            onChange={handleChangeContent}
          />
        </div>
      </div>
    </div>
  )
}

export default WebContentPage
