import { useContext, useState } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

import { API } from 'API'
import { CasesPageContext } from 'containers/CasesPage/CasesPageContext'
import { RequestToasterContext } from 'containers/Providers'
import { Case } from 'domains/case'
import { ComboType } from 'domains/case/ComboType'
import { StandardErrorResponse } from 'types/APIResponses'
import { isNotNil } from 'utils/isNotNil'

interface RollbackModalProps {
  isRollbackDialogOpen: boolean
  changeRollbackDialogStateTo: (it: boolean) => void
}

export const RollbackModal = ({
  isRollbackDialogOpen,
  changeRollbackDialogStateTo,
}: RollbackModalProps) => {
  const { casesPageState, getCase } = useContext(CasesPageContext)
  const { caseStatus, operator, id, childComboTreatment } = casesPageState.userCase as Case
  const { rollback } = casesPageState

  const { requestStatusRef } = useContext(RequestToasterContext)
  const [isRollbackSendEmail, setIsRollSendEmail] = useState(true)
  const handleSetIsRollbackSendEmail = (event: React.ChangeEvent<HTMLInputElement>) =>
    setIsRollSendEmail(event.target.checked)

  const [rollbackNotes, setRollbackNotes] = useState('')
  const handleSetRollbackNotes = (event: React.ChangeEvent<HTMLTextAreaElement>) =>
    setRollbackNotes(event.target.value)

  const handleCloseDialog = () => {
    setIsRollSendEmail(true)
    changeRollbackDialogStateTo(false)
    setRollbackNotes('')
  }

  const handleRollbackStatus = () => {
    if (rollbackNotes === '' || rollbackNotes.match(/^ *$/) !== null) {
      alert('Please enter notes')
    } else {
      changeRollbackDialogStateTo(false)
      requestStatusRef.current?.startProgress('Rolling back...', 'secondary')
      API.updateCaseById({
        id: id,
        rollbackStatus: true,
        rollbackNotes: rollbackNotes,
        rollbackSendEmail: isRollbackSendEmail,
      })
        .then(() => {
          getCase()
          requestStatusRef.current?.showAlert('Done', 'success')
        })
        .catch((error: StandardErrorResponse) => {
          requestStatusRef.current?.showAlert(error.response.data.error, 'danger')
        })
    }
  }

  const handleCancelComboSpk = () => {
    changeRollbackDialogStateTo(false)
    requestStatusRef.current?.startProgress('Canceling combo SPK...', 'secondary')
    API.deleteComboTreatmentByParentId(id)
      .then(() => {
        getCase()
        requestStatusRef.current?.showAlert('Done', 'success')
      })
      .catch((error: StandardErrorResponse) => {
        requestStatusRef.current?.showAlert(error.response.data.error, 'danger')
      })
  }

  if (childComboTreatment && childComboTreatment.comboType === ComboType.Spk) {
    return (
      <Modal isOpen={isRollbackDialogOpen} className="w-25" centered>
        <ModalHeader className="d-inline-flex flex-column w-100 align-items-center">
          <span>Cancel SPK case</span>
        </ModalHeader>
        <ModalBody>Are you sure?</ModalBody>
        <ModalFooter>
          <button type="button" onClick={handleCancelComboSpk}>
            Yes
          </button>
          <button type="button" onClick={handleCloseDialog}>
            Close
          </button>
        </ModalFooter>
      </Modal>
    )
  }

  if (!rollback) {
    return <></>
  }

  const hasRollback = rollback?.hasOwnProperty(caseStatus) === true

  return (
    <Modal isOpen={isRollbackDialogOpen} className="w-25" centered>
      <ModalHeader className="d-inline-flex flex-column w-100 align-items-center">
        <span>Rollback status to</span>
        {hasRollback && <small className="float-right">{rollback[caseStatus]}</small>}
      </ModalHeader>
      <ModalBody>
        <textarea
          value={rollbackNotes}
          name="rollbackNotes"
          onChange={handleSetRollbackNotes}
          placeholder="notes (required)"
          rows={3}
          className="w-100"
        />
        {isNotNil(operator) && (
          <div>
            <input
              checked={isRollbackSendEmail}
              type="checkbox"
              id="rollbackSendEmail"
              onChange={handleSetIsRollbackSendEmail}
            />
            <label className="ml-1" htmlFor="rollbackSendEmail">
              Notify operator
            </label>
            <div>
              {`${operator.firstName} ${operator.lastName}`}
              {`< ${operator.email} >`}
            </div>
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <button type="button" onClick={handleRollbackStatus}>
          Rollback
        </button>
        <button type="button" onClick={handleCloseDialog}>
          Close
        </button>
      </ModalFooter>
    </Modal>
  )
}
