import { Scan } from 'domains/scan'
import { EmptyAPIResponse } from 'types/APIResponses'
import { WithId } from 'types/utility'

import { protectedApi } from './requester'

export const getScanners = () => {
  return protectedApi.get<WithId<Scan>[]>(`/scanners`).then((response) => response.data)
}

export const updateScannerById = ({ id, ...params }: WithId<Scan>) => {
  return protectedApi.put<EmptyAPIResponse>(`/scanner/${id}`, {}, { params })
}

export const postNewScanner = (scanner: Scan) => {
  return protectedApi.post<EmptyAPIResponse>(`/scanner`, {}, { params: scanner })
}

export const DeleteScanner = (id: number) => {
  return protectedApi.delete<EmptyAPIResponse>(`/scanner/${id}`)
}
