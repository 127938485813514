import React, { useState, useContext } from 'react'
import { Container, Table } from 'reactstrap'

import { API } from 'API'
import { RequestToasterContext } from 'containers/Providers'
import { StandardErrorResponse } from 'types/APIResponses'

import { CaseCheckbox } from './components'
import { CaseWithState } from './models'

export const TransferPage = () => {
  const [isTransferring, setIsTransferring] = useState(false)
  const [fromUser, setFromUser] = useState('')
  const [toUser, setToUser] = useState('')
  const [cases, setCases] = useState<CaseWithState[]>([])
  const { requestStatusRef } = useContext(RequestToasterContext)

  const handleListCases = () => {
    requestStatusRef.current?.startProgress('Getting cases...', 'secondary')
    API.getCasesByUsername(fromUser)
      .then((response) => {
        setCases(
          response.map((caseItem) => ({
            ...caseItem,
            selected: false,
            transfer: '',
          })),
        )
        requestStatusRef.current?.showAlert('Done', 'success')
      })
      .catch((error: StandardErrorResponse) => {
        setCases([])
        requestStatusRef.current?.showAlert(error.response.data.error, 'danger')
      })
  }
  const handleTransfer = () => {
    const publicCaseIdsToTransfer = cases.filter(({ selected }) => selected).map(({ id }) => id)

    if (!window.confirm('Transfer cases from ' + fromUser + ' to ' + toUser)) {
      return
    }

    requestStatusRef.current?.startProgress('Transferring cases...', 'secondary')
    setIsTransferring(true)

    API.transferCases({ toUsername: toUser, publicCaseIds: publicCaseIdsToTransfer })
      .then((errorMessage?: string) => {
        if (errorMessage !== undefined && errorMessage !== '') {
          return requestStatusRef.current?.showAlert(errorMessage, 'danger')
        }
        requestStatusRef.current?.showAlert('Done', 'success')
        setCases((prev) => prev.filter(({ selected }) => !selected))
      })
      .finally(() => setIsTransferring(false))
  }

  const handleSelectAllCases = () => {
    setCases(
      cases.map((caseItem) => ({
        ...caseItem,
        selected: true,
      })),
    )
  }
  const handleDeselectAllCases = () => {
    setCases(
      cases.map((caseItem) => ({
        ...caseItem,
        selected: false,
      })),
    )
  }
  const handleInvertSelection = () => {
    setCases(
      cases.map((caseItem) => ({
        ...caseItem,
        selected: !caseItem.selected,
      })),
    )
  }
  const handleChangeFromUser = (event: React.ChangeEvent<HTMLInputElement>) =>
    setFromUser(event.target.value)
  const handleChangeToUser = (event: React.ChangeEvent<HTMLInputElement>) =>
    setToUser(event.target.value)

  const casesToTransferSelected = cases.some(({ selected }) => selected)

  return (
    <Container>
      <h2>Transfer cases</h2>
      <form>
        <Table bordered className="w-75">
          <tbody>
            <tr>
              <td>From doctor</td>
              <td>
                <input
                  type="text"
                  name="fromUser"
                  placeholder="From doctor"
                  onChange={handleChangeFromUser}
                  className="mr-2"
                  value={fromUser}
                />
                <button
                  onClick={handleListCases}
                  className="float-right"
                  type="button"
                  disabled={!fromUser || isTransferring}>
                  List cases
                </button>
              </td>
            </tr>
            <tr>
              <td>To doctor</td>
              <td>
                <input
                  type="text"
                  name="toUser"
                  placeholder="To doctor"
                  onChange={handleChangeToUser}
                  className="mr-2"
                  value={toUser}
                />
                <button
                  onClick={handleTransfer}
                  className="float-right"
                  type="button"
                  disabled={!toUser || !casesToTransferSelected || isTransferring}>
                  Transfer
                </button>
              </td>
            </tr>
          </tbody>
        </Table>
      </form>
      <h2>Cases</h2>
      <div className="m-1">
        <button onClick={handleSelectAllCases} type="button" className="mr-1">
          Select all
        </button>
        <button onClick={handleDeselectAllCases} type="button" className="mr-1">
          Unselected all
        </button>
        <button onClick={handleInvertSelection} type="button" className="mr-1">
          Invert selection
        </button>
      </div>
      <Table bordered className="w-75 m-1">
        <thead>
          <tr>
            <th>CaseID</th>
            <th>Patient</th>
            <th>Status</th>
            <th>Doctor</th>
          </tr>
        </thead>
        <tbody>
          {cases.map((item, index) => (
            <tr key={item.id}>
              <td>
                <CaseCheckbox
                  index={index}
                  cases={cases}
                  changeCases={setCases}
                  disabled={isTransferring}
                />
                {item.id}
              </td>
              <td>
                {item.patient.personFirstName} {item.patient.personLastName}
                <br />
                {item.patient.personBirthDate}
              </td>
              <td>
                {item.caseStatus}
                <br />
                {item.cancelled ? '(cancelled)' : ''}
              </td>
              <td>{item.doctor.username}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  )
}
