import { PVSSearchResponse } from 'domains/pvs'
import { EmptyAPIResponse } from 'types/APIResponses'

import { protectedApi } from './requester'

interface SearchPVSOptions {
  searchCaseId: string
  searchTicket: string
  emailLanguage: string
}

export const searchPVS = async ({
  searchCaseId,
  searchTicket,
  emailLanguage,
}: SearchPVSOptions) => {
  return protectedApi
    .get<PVSSearchResponse>(`/pvs/ticket/${searchCaseId}/${searchTicket}/${emailLanguage}`)
    .then((response) => response.data)
}

interface SendPVSTicketOptions {
  searchTicket: string
  data: string
}

export const sendPVSTicket = async ({ searchTicket, data }: SendPVSTicketOptions) => {
  return protectedApi.post<EmptyAPIResponse>(`/pvs/ticket/${searchTicket}/email`, data)
}
