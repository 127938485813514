import React from 'react'

import { CaseWithState } from 'containers/TransferPage/models'

interface CaseCheckboxProps {
  cases: CaseWithState[]
  index: number
  changeCases: (cases: CaseWithState[]) => void
  disabled: boolean
}

export const CaseCheckbox = ({ cases, index, changeCases, disabled }: CaseCheckboxProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newCases = [...cases]
    newCases[index].selected = event.target.checked
    changeCases(newCases)
  }

  return (
    <input
      id={`selected-${index}`}
      type="checkbox"
      checked={cases[index].selected}
      className="mr-2"
      onChange={handleChange}
      disabled={disabled}
    />
  )
}
