import React, { useContext, useRef, useState } from 'react'
import { Container } from 'reactstrap'

import { API } from 'API'
import { RequestToasterContext } from 'containers/Providers'
import { saveAs } from 'file-saver'
import { StandardErrorResponse } from 'types/APIResponses'

export const ERPAddressIDsPage = () => {
  const [importAddressesStatus, setImportAddressesStatus] = useState('')

  const { requestStatusRef } = useContext(RequestToasterContext)
  const fileInputRef = useRef<HTMLInputElement>(null)

  const handleExportAddresses = () => {
    requestStatusRef.current?.startProgress('Exporting file...', 'secondary')
    API.getAccountsActiveAddresses()
      .then((response) => {
        saveAs(new Blob([response]), 'active-addresses.xlsx')
        requestStatusRef.current?.showAlert('File exported', 'success')
      })
      .catch((error: StandardErrorResponse) => {
        requestStatusRef.current?.showAlert(error.response.data.error, 'danger')
      })
  }

  const handleImportAddresses = () => {
    const file = fileInputRef.current?.files?.item(0) ?? null

    if (!file) {
      alert('No file chosen')
      return
    }
    requestStatusRef.current?.startProgress('Importing file...', 'secondary')
    API.importAccountsExternalAddressIds(file)
      .then((result) => {
        requestStatusRef.current?.showAlert('File imported', 'success')
        setImportAddressesStatus(result)
      })
      .catch((error: StandardErrorResponse) => {
        requestStatusRef.current?.showAlert(error.response.data.error, 'danger')
      })
  }

  return (
    <Container>
      <h2>Manage ERP Address IDs</h2>
      <pre className="mt-4 small">{importAddressesStatus}</pre>
      <div className="w-75 border p-2 mt-4 bg-light small">
        <ol>
          <li className="mt-1">
            Export file with addresses{' '}
            <button className="small" onClick={handleExportAddresses}>
              Export
            </button>
          </li>
          <li className="mt-1">
            Edit file:
            <ul>
              <li>DO NOT delete header row (or if you copy rows to another file, copy it too)</li>
              <li>DO NOT modify "id" and "account_id" columns</li>
              <li>YOU CAN DELETE rows that you do not want to be modified</li>
              <li>
                Update <b>external_address_id</b>, <b>rel_customer_num</b> and{' '}
                <b>rel_customer_name</b> that need to be updated
              </li>
            </ul>
          </li>
          <li className="mt-1">Better split file into smaller chunks to load</li>
          <li className="mt-1">
            Select edited file <input className="small" ref={fileInputRef} type="file" />
          </li>
          <li className="mt-1">
            Import file{' '}
            <button className="small" onClick={handleImportAddresses}>
              Import
            </button>
          </li>
          <li className="mt-1">Verify correctness</li>
        </ol>
      </div>
    </Container>
  )
}
