import { FC, useEffect, useState } from 'react'

import { GenericFeatureFlags } from 'domains/featureFlags'

import { PermissionValue } from './PermissionValue'
import { PermissionsGroupHeader } from './PermissionsGroupHeader'

interface PermissionItemProps {
  flagsData: GenericFeatureFlags
  path?: string[]
  searchQuery?: string
}
export const PermissionItem: FC<PermissionItemProps> = ({
  path = [],
  flagsData,
  searchQuery = '',
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const toggleHiddenPart = () => setIsOpen((value) => !value)

  const isSearchedItem =
    path.length > 0 && path[path.length - 1].toLowerCase().includes(searchQuery)

  useEffect(() => {
    if (!searchQuery) {
      setIsOpen(false)
      return
    }

    if (Boolean(searchQuery) && !isSearchedItem) {
      setIsOpen(true)
    }
  }, [searchQuery])

  const level = path.length
  const backgroundAccordingToLevel = '#000000' + ('0' + 8 * level).slice(-2)
  const levelBackgroundStyle = { backgroundColor: backgroundAccordingToLevel }
  const levelPaddingStyle = { paddingLeft: 12 + level * 16 }

  return (
    <>
      {level > 0 && (
        <PermissionsGroupHeader
          level={level - 1}
          name={path[path.length - 1]}
          isOpen={isOpen}
          toggleHiddenPart={toggleHiddenPart}
        />
      )}

      {(level == 0 || isOpen) &&
        Object.keys(flagsData)
          .sort((a, b) => (a.toLowerCase() > b.toLowerCase() ? 1 : -1))
          .map((ffName) => {
            const ffData = flagsData[ffName]

            if (typeof ffData == 'object' && !Array.isArray(ffData)) {
              return (
                <PermissionItem
                  key={ffName}
                  flagsData={ffData}
                  path={[...path, ffName]}
                  searchQuery={isSearchedItem ? '' : searchQuery}
                />
              )
            }

            return (
              <tr key={ffName} style={levelBackgroundStyle}>
                <td className="w-50" style={{ ...levelPaddingStyle, overflowWrap: 'anywhere' }}>
                  <span title={[...path, ffName].join('.')}>{ffName}</span>
                </td>
                <td className="w-50" style={{ overflowWrap: 'anywhere' }}>
                  <PermissionValue rawValue={ffData} />
                </td>
              </tr>
            )
          })}
    </>
  )
}
