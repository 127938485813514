import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

import { AppRoutes } from 'constants/appRoutes'
import { isLoggedIn } from 'core/auth'

export const PrivateRoute = () => {
  const isAuthenticated = isLoggedIn()

  return isAuthenticated ? <Outlet /> : <Navigate to={AppRoutes.login} />
}
