import { useFormContext } from 'react-hook-form'

import {
  FeatureFlagsSchemaObjectEntry,
  FeatureFlagValue,
  GenericFeatureFlags,
} from 'domains/featureFlags'
import { getValueByPath } from 'utils/getValueByPath'

import { FeatureFlagItem } from './FeatureFlagItem'

interface FeatureFlagObjectProps {
  path: string[]
  ffSchemaObject: FeatureFlagsSchemaObjectEntry
  dbFFObject?: GenericFeatureFlags
}

export const FeatureFlagObject = ({
  path = [],
  ffSchemaObject,
  dbFFObject = {},
}: FeatureFlagObjectProps) => {
  const form = useFormContext<GenericFeatureFlags>()

  const visibleProperties =
    !ffSchemaObject.dependentOn || form.watch(ffSchemaObject.dependentOn)
      ? Object.entries(ffSchemaObject.properties).filter((entry) => entry[1].visible)
      : []

  const hideTitle =
    visibleProperties.length === 0 ||
    (visibleProperties.length === 1 && visibleProperties[0][1].title === ffSchemaObject.title)

  return (
    <>
      {!hideTitle && <p className="mb-0">{ffSchemaObject.title}</p>}

      {visibleProperties.map((entry) => {
        const ffKey = entry[0]
        const ffData = entry[1]

        return ffData.type === 'object' ? (
          <FeatureFlagObject
            key={ffKey}
            path={[...path, ffKey]}
            ffSchemaObject={ffData}
            dbFFObject={getValueByPath(dbFFObject, ffKey) as GenericFeatureFlags}
          />
        ) : (
          <FeatureFlagItem
            key={ffKey}
            path={[...path, ffKey].join('.')}
            ffSchemaItem={ffData}
            dbFFItem={getValueByPath(dbFFObject, ffKey) as FeatureFlagValue | FeatureFlagValue[]}
            nested
          />
        )
      })}
    </>
  )
}
