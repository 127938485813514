export const photoLabels = {
  OTHER: 'Composite',
  EXTRAORAL_FRONTAL_REST: 'Ext Frontal Rest',
  EXTRAORAL_FRONTAL_SMILING: 'Ext Frontal smiling',
  EXTRAORAL_PROFILE_LEFT: 'Ext Profile Left',
  EXTRAORAL_PROFILE_RIGHT: 'Ext Profile Right',
  INTRAORAL_FRONTAL: 'Int Frontal',
  INTRAORAL_BUCCAL_LEFT: 'Int Buccal Left',
  INTRAORAL_BUCCAL_RIGHT: 'Int Buccal Right',
  INTRAORAL_OCCLUSAL_LOWER: 'Int Occlusal Lower',
  INTRAORAL_OCCLUSAL_UPPER: 'Int Occlusal Upper',
  XRAY_LATERAL: 'X-Ray Lateral',
  XRAY_PANORAMIC: 'X-Ray Panoramic',
} as const
