import React, { useContext, useState, useEffect } from 'react'

import { API } from 'API'
import { CasesPageContext } from 'containers/CasesPage/CasesPageContext'
import { RequestToasterContext } from 'containers/Providers'
import { Case } from 'domains/case'
import { ProductInfo } from 'domains/productInfo'
import { Treatment } from 'domains/treatment'
import { StandardErrorResponse } from 'types/APIResponses'
import { isNotNil } from 'utils/isNotNil'

export const CaseAlignerMaterial = () => {
  const { casesPageState, getCase } = useContext(CasesPageContext)
  const { id } = casesPageState.treatment as Treatment
  const { alignerMaterial: initialAlignerMaterial } = casesPageState.treatment
  const { productInfoId } = casesPageState.userCase as Case
  const { requestStatusRef } = useContext(RequestToasterContext)

  const [alignerMaterial, setAlignerMaterial] = useState(initialAlignerMaterial ?? '')

  useEffect(() => {
    if (isNotNil(initialAlignerMaterial)) {
      setAlignerMaterial(initialAlignerMaterial)
    }
  }, [initialAlignerMaterial])

  const handleChangeAlignerMaterial = (event: React.ChangeEvent<HTMLSelectElement>) =>
    setAlignerMaterial(event.target.value)

  const handleUpdateAlignerMaterial = () => {
    if (!alignerMaterial) {
      return
    }

    API.updateTreatment({
      id,
      alignerMaterial,
    })
      .then(() => {
        getCase()
        requestStatusRef.current?.showAlert('Updated', 'success')
      })
      .catch((error: StandardErrorResponse) => {
        requestStatusRef.current?.showAlert(error.response.data.error, 'danger')
      })
  }
  const isMaterialHidden = productInfoId == ProductInfo.Retainers

  return (
    <tr>
      <td>Aligner Material</td>
      {!isMaterialHidden && (
        <td>
          <select value={alignerMaterial} onChange={handleChangeAlignerMaterial} className="w-50">
            {casesPageState.alignerMaterials.map((_, index) => (
              <option key={index} value={casesPageState.alignerMaterials[index]}>
                {casesPageState.alignerMaterials[index]}
              </option>
            ))}
          </select>

          <button onClick={handleUpdateAlignerMaterial} className="float-right" type="button">
            Update
          </button>
        </td>
      )}
    </tr>
  )
}
