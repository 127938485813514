const falsyValues = ['', 0, false]

export const compareFFItems = (a: unknown, b: unknown) => {
  if (Object.is(a, b)) {
    return true
  }

  const aEmptyArray = !a || (Array.isArray(a) && a.length === 0)
  const bEmptyArray = !b || (Array.isArray(b) && b.length === 0)

  if (aEmptyArray && bEmptyArray) {
    return true
  }

  const bSorted = (Array.isArray(b) ? b : []).slice().sort()
  const equalArrays =
    Array.isArray(a) &&
    Array.isArray(bSorted) &&
    a.length === bSorted.length &&
    a
      .slice()
      .sort()
      .every((value, index) => value === bSorted[index])

  if (equalArrays) {
    return true
  }

  const aFalsy = !a || falsyValues.find((elem) => elem === a)
  const bFalsy = !b || falsyValues.find((elem) => elem === b)

  if (aFalsy && bFalsy) {
    return true
  }

  return false
}

export const featureFlagsDiff = (obj1: Record<string, unknown>, obj2: Record<string, unknown>) => {
  const result: Record<string, unknown> = {}

  if (compareFFItems(obj1, obj2)) {
    return undefined
  }

  if (!obj2 || typeof obj2 !== 'object' || Array.isArray(obj2)) {
    return obj2
  }

  Object.keys(obj2 || {}).forEach((key) => {
    const value = featureFlagsDiff(
      obj1?.[key] as Record<string, unknown>,
      obj2[key] as Record<string, unknown>,
    )

    if (
      value !== undefined &&
      (typeof value !== 'object' || Array.isArray(value) || Object.keys(value).length > 0)
    ) {
      result[key] = value
    }
  })

  return result
}
