import React, { Dispatch, useContext } from 'react'
import { Table } from 'reactstrap'

import { API } from 'API'
import { PVSReducerActions } from 'containers/PVSPage/actions'
import { PVSState } from 'containers/PVSPage/reducer'
import { RequestToasterContext } from 'containers/Providers'
import { StandardErrorResponse } from 'types/APIResponses'
import { isNotNil } from 'utils/isNotNil'

import { PVSAttachmentItem } from '../PVSAttachmentItem'

interface PVSContentProps {
  pvsCase: PVSState
  dispatchToPageState: Dispatch<PVSReducerActions>
  searchTicket: string
}

export const PVSPageContent = ({ pvsCase, dispatchToPageState, searchTicket }: PVSContentProps) => {
  const {
    id,
    originalCaseId,
    orderIndicator,
    doctor,
    reorder,
    doctor_email,
    pvsTicketBcc,
    emailSubject,
    emailContent,
    summary,
    description,
    attachments,
  } = pvsCase
  const { requestStatusRef } = useContext(RequestToasterContext)

  const handleSend = () => {
    requestStatusRef.current?.startProgress('Sending email...', 'secondary')
    API.sendPVSTicket({
      searchTicket,
      data: JSON.stringify({
        to: doctor_email,
        bcc: pvsTicketBcc,
        subject: emailSubject,
        content: emailContent,
        attachmentIds: attachments.map((item) => item.id),
      }),
    })
      .then(() => {
        requestStatusRef.current?.showAlert('Sent', 'success')
      })
      .catch((error: StandardErrorResponse) => {
        requestStatusRef.current?.showAlert(error.response.data.error, 'danger')
      })
  }

  const handleRemoveAttachment = (id: number) => {
    dispatchToPageState({
      type: 'setPVSPageValue',
      payload: { attachments: attachments.filter((item) => item.id !== id) },
    })
  }

  const handleChangeStateValue = (
    event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>,
  ) =>
    dispatchToPageState({
      type: 'setPVSPageValue',
      payload: { [event.target.name]: event.target.value },
    })

  return (
    <div className="m-1 mt-4">
      <div>Verify details and send</div>
      <Table bordered className="w-75">
        <tbody>
          <tr>
            <td>Doctor</td>
            <td>
              {doctor.firstName} {doctor.lastName}
            </td>
          </tr>
          <tr>
            <td>Case</td>
            <td>
              {<span className="mr-1">Case ID: {id}</span>}
              {isNotNil(originalCaseId) && (
                <span className="mr-1">| Original Case ID: {originalCaseId}</span>
              )}
              {isNotNil(orderIndicator) && (
                <span className="mr-1">| Order indicator: {orderIndicator}</span>
              )}
              {isNotNil(reorder) && reorder && <span className="mr-1">| Reorder</span>}
            </td>
          </tr>
          <tr>
            <td>To email(s)</td>
            <td>
              <input
                className="w-100"
                name="doctor_email"
                value={doctor_email}
                placeholder="TO"
                onChange={handleChangeStateValue}
              />
            </td>
          </tr>
          <tr>
            <td>BCC</td>
            <td>
              <input
                className="w-100"
                name="pvsTicketBcc"
                value={pvsTicketBcc}
                placeholder="BCC"
                onChange={handleChangeStateValue}
              />
            </td>
          </tr>
          <tr>
            <td>Subject</td>
            <td>
              <input
                className="w-100"
                name="emailSubject"
                value={emailSubject}
                onChange={handleChangeStateValue}
              />
            </td>
          </tr>
          <tr>
            <td>Content</td>
            <td>
              <textarea
                className="w-100"
                rows={15}
                name="emailContent"
                value={emailContent}
                onChange={handleChangeStateValue}
              />
            </td>
          </tr>
          <tr>
            <td>Jira issue</td>
            <td>
              <p>
                <a target="_blank" href={'https://ormcornd.atlassian.net/browse/' + searchTicket}>
                  {'https://ormcornd.atlassian.net/browse/' + searchTicket}
                </a>
              </p>
              <b>{summary}</b>
              <p>{description}</p>
            </td>
          </tr>
          <tr>
            <td>Attachment(s)</td>
            <td>
              <p>Remove files that might be too large to be emailed, like STL files</p>
              <ul>
                {attachments.map((item, index) => (
                  <PVSAttachmentItem
                    key={`${item.filename}-${index}`}
                    attachment={item}
                    removeAttachment={handleRemoveAttachment}
                  />
                ))}
              </ul>
            </td>
          </tr>
          <tr>
            <td colSpan={2} className="text-right">
              <button onClick={handleSend} className="m-1 w-25 ml-4" type="button">
                Send
              </button>
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  )
}
