import { memo } from 'react'
import { Container } from 'reactstrap'

import { Principal } from '../Principal'
import { Version } from '../Version'

export const Footer = memo(() => (
  <footer className="footer">
    <Container>
      <Version />
      <Principal />
    </Container>
  </footer>
))
