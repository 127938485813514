import { ChangeEvent } from 'react'
import { FormGroup, Input, Label } from 'reactstrap'

interface FeatureFlagBooleanItemProps {
  title: string
  value: boolean
  onChange: (value: boolean) => void
  disabled?: boolean
}

export const FeatureFlagBooleanItem = ({
  title,
  value,
  onChange,
  disabled,
}: FeatureFlagBooleanItemProps) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => onChange(event.target.checked)

  return (
    <FormGroup check>
      <Label className="mb-0">
        <Input type="checkbox" disabled={disabled} checked={value} onChange={handleChange} />
        {title}
      </Label>
    </FormGroup>
  )
}
