import { createContext } from 'react'

import { RequestStatus } from 'components/RequestStatus'

interface RequestToasterContext {
  requestStatusRef: React.RefObject<RequestStatus>
}

export const RequestToasterContext = createContext<RequestToasterContext>({
  requestStatusRef: { current: null },
})
