import { AxiosRequestConfig } from 'axios'

export const getFileHeaders = async (config: AxiosRequestConfig) => {
  config.headers = {
    Accept: 'application/json',
    'Content-Type': 'multipart/form-data',
    ...config.headers,
  }

  return config
}
