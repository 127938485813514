export const Languages = {
  en: 'English',
  de: 'German',
  it: 'Italian',
  fr: 'French',
  zh: 'Chinese',
  es: 'Spanish',
  ja: 'Japanese',
  ru: 'Russian',
} as const
