import { FC, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Container } from 'reactstrap'

import { API } from 'API'
import { AppRoutes } from 'constants/appRoutes'
import { RequestToasterContext } from 'containers/Providers'
import { GenericFeatureFlags } from 'domains/featureFlags'
import { StandardErrorResponse } from 'types/APIResponses'

import { PermissionsContext } from './PermissionsContext/PermissionsContext'
import { PermissionsFilter } from './PermissionsFilter'
import { PermissionsTable } from './PermissionsTable'
import { filterObjectByKey, filterObjectByNullishValues } from './utils'

interface PermissionsInfoProps {
  username: string
}

export const PermissionsInfo: FC<PermissionsInfoProps> = ({ username }) => {
  const navigate = useNavigate()
  const { requestStatusRef } = useContext(RequestToasterContext)
  const [application, setApplication] = useState('Web')
  const [filterQuery, setFilterQuery] = useState('')
  const [hideNullish, setHideNullish] = useState(true)
  const [flags, setFlags] = useState<GenericFeatureFlags>({})

  useEffect(() => {
    handleGet()
  }, [username, application])

  const handleGet = () => {
    if (!username || !application) {
      return
    }

    requestStatusRef.current?.startProgress('Getting permissions info...', 'secondary')
    API.getUsersFeatureFlags(encodeURIComponent(username), application)
      .then((featureFlags) => {
        setFlags(featureFlags)
        navigate(`${AppRoutes.permissions}/${username}`)
        requestStatusRef.current?.showAlert('Done', 'success')
      })
      .catch((error: StandardErrorResponse) => {
        setFlags({})
        requestStatusRef.current?.showAlert(error.response.data.error, 'danger')
      })
  }

  const nullishFilteredFF = hideNullish ? filterObjectByNullishValues(flags) : flags
  const queryFilteredFF = filterQuery
    ? filterObjectByKey(nullishFilteredFF, filterQuery.toLowerCase())
    : nullishFilteredFF

  return (
    <Container className="d-flex flex-column mt-4">
      <PermissionsContext username={username} application={application} />
      <div className="d-flex align-items-start">
        <PermissionsTable searchQuery={filterQuery.toLowerCase()} flagsData={queryFilteredFF} />
        <PermissionsFilter
          application={application}
          onApplicationChanged={setApplication}
          hideNullish={hideNullish}
          onHideNullishChanged={setHideNullish}
          filterQuery={filterQuery}
          onFilterQueryChanged={setFilterQuery}
        />
      </div>
    </Container>
  )
}
