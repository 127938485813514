import { useContext } from 'react'

import { API } from 'API'
import { CasesPageContext } from 'containers/CasesPage/CasesPageContext'
import { RequestToasterContext } from 'containers/Providers'
import { Case } from 'domains/case'
import { StandardErrorResponse } from 'types/APIResponses'

export const MesDesignApproval = () => {
  const { casesPageState, getCase } = useContext(CasesPageContext)
  const { id, erpSoCreation, erpSoBook } = casesPageState.userCase as Case
  const { requestStatusRef } = useContext(RequestToasterContext)
  const mesDesignApprovalEnabled = Boolean(erpSoCreation) && Boolean(erpSoBook)

  const handleMesDesignApproval = () => {
    API.mesDesignApproval(id)
      .then(() => {
        getCase()
        requestStatusRef.current?.showAlert('Updated', 'success')
      })
      .catch((error: StandardErrorResponse) => {
        requestStatusRef.current?.showAlert(error.response.data.error, 'danger')
      })
  }
  return (
    <tr>
      <td>MES Design Approval</td>
      <td>
        <span>
          If button is disabled,
          <br />
          check if both Sales Order Creation and Booking are set
        </span>
        <button
          type="button"
          className="float-right"
          disabled={!mesDesignApprovalEnabled}
          onClick={handleMesDesignApproval}>
          Send MES Design Approval
        </button>
      </td>
    </tr>
  )
}
