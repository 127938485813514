import { useContext, useState } from 'react'

import { CasesPageContext } from 'containers/CasesPage/CasesPageContext'
import { Case, CaseStatusMatrix, CaseStatusRollback } from 'domains/case'
import { ComboTreatment } from 'domains/case/ComboTreatment'
import { ComboType } from 'domains/case/ComboType'
import Tooltip from 'rc-tooltip'
import { Nullable } from 'types/utility'
import { isNotNil } from 'utils/isNotNil'

import { RollbackModal } from './RollbackModal'

interface RollbackStatusButtonProps {
  childComboTreatment: Nullable<ComboTreatment>
  rollback: Nullable<CaseStatusRollback>
  caseStatus: keyof typeof CaseStatusMatrix
  handleOpenDialog: () => void
}

const RollbackStatusButton = ({
  childComboTreatment,
  rollback,
  caseStatus,
  handleOpenDialog,
}: RollbackStatusButtonProps) => {
  if (childComboTreatment && childComboTreatment.comboType === ComboType.Spk) {
    return (
      <>
        <Tooltip
          overlay={
            <span>This SPK case will be cancelled and moved back to Spark Aligner product.</span>
          }>
          <button
            onClick={handleOpenDialog}
            className="float-right d-inline-flex flex-column"
            type="button">
            <span>Cancel SPK case</span>
          </button>
        </Tooltip>
      </>
    )
  }

  const hasRollback = rollback?.hasOwnProperty(caseStatus) === true

  return (
    <button
      disabled={rollback?.hasOwnProperty(caseStatus) === false}
      onClick={handleOpenDialog}
      className="float-right d-inline-flex flex-column"
      type="button">
      <span>Rollback status to</span>
      {hasRollback && <small className="float-right">{rollback[caseStatus]}</small>}
    </button>
  )
}

export const CaseStatus = () => {
  const { casesPageState } = useContext(CasesPageContext)
  const {
    caseStatus,
    operatorId,
    scanningOperatorId,
    anatomyOperatorId,
    designOperatorId,
    designQcOperatorId,
    manufacturingOperatorId,
    shippingOperatorId,
    childComboTreatment,
  } = casesPageState.userCase as Case
  const { rollback } = casesPageState

  const [isRollbackDialogOpen, setIsRollbackDialogOpen] = useState(false)
  const handleOpenDialog = () => {
    setIsRollbackDialogOpen(true)
  }

  return (
    <tr>
      <td>Case status</td>
      <td>
        <form>
          <span>{caseStatus}</span>
          <RollbackStatusButton
            childComboTreatment={childComboTreatment}
            rollback={rollback}
            caseStatus={caseStatus}
            handleOpenDialog={handleOpenDialog}
          />
          <RollbackModal
            isRollbackDialogOpen={isRollbackDialogOpen}
            changeRollbackDialogStateTo={setIsRollbackDialogOpen}
          />
        </form>
        <small>
          <span className="mr-1">Operator: {operatorId ?? 'NULL'}</span>
          {isNotNil(scanningOperatorId) && (
            <span className="mr-1">| Scanning: {scanningOperatorId}</span>
          )}
          {isNotNil(anatomyOperatorId) && (
            <span className="mr-1">| Anatomy: {anatomyOperatorId}</span>
          )}
          {isNotNil(designOperatorId) && <span className="mr-1">| Design: {designOperatorId}</span>}
          {isNotNil(designQcOperatorId) && (
            <span className="mr-1">| Design QC: {designQcOperatorId}</span>
          )}
          {isNotNil(manufacturingOperatorId) && (
            <span className="mr-1">| Mfg: {manufacturingOperatorId}</span>
          )}
          {isNotNil(shippingOperatorId) && (
            <span className="mr-1">| Shipping: {shippingOperatorId}</span>
          )}
        </small>
      </td>
    </tr>
  )
}
